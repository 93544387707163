import Home from '../containers/home/Home';

import Test from '../containers/home/Test';
import Sandip from '../containers/home/Sandip';
import Niranjan from '../containers/home/Niranjan';
import Page from '../containers/page/index';
import FeedbackForm2 from '../components/feedback-form/2';
import Page1 from '../themes/page/1';
import ViewResult from 'containers/view-result/ViewResult';

export default [
  {
    path: '/',
    component: Home,
    exact: true,
  },
  {
    path: '/_test',
    component: Test,
    exact: true,
  },
  {
    path: '/_sandip',
    component: Sandip,
    exact: true,
  },
  {
    path: '/_niranjan',
    component: Niranjan,
    exact: true,
  },
  {
    path: '/admin',
    component: () => {
      const adminRoute =
        window.location.protocol +
        '//' +
        (window.location.hostname === 'localhost'
          ? '127.0.0.1:8000'
          : window.location.hostname === 'cao.p2.gov.np'
            ? 'website.edigitalnepal.edu.np'
            : window.location.hostname) +
        '/admin';
      window.location.href = adminRoute;
      return null;
    },
    exact: true,
  },
  {
    path: '/feedback-form',
    component: FeedbackForm2,
    exact: true,
  },
  {
    path: '/view-result',
    component: ViewResult,
    exact: true,
  },
  {
    path: '/:slugMenu/:slugSubMenu?/:slugMenuLink?/:slugSubMenuLink?',
    component: Page,
    exact: true,
  },
];
