import React from 'react';
import { Link } from 'react-router-dom';
import FA from 'react-fontawesome';
import injectSheet from 'react-jss';

// asset import
import './List.css';
import RpsharmaDetailPage from '../../rpsharma-website/rpsharma-detail-page';
import MillsberryClzDetailPage from '../../millsberry-college/millsberry-clz-detail-page';
import CurrentIssue from '../../../themes/content-block/current-issues';
import CareerVacancies3 from 'components/career-vacancies/3';
import CareerVacancies2 from 'components/career-vacancies/2';
import CareerVacancies5 from 'components/career-vacancies/5';
import ArchiveList from '../../../themes/content-block/archive-list';
import HTMLReactParser from 'html-react-parser';
import AitmBannerImage from '../../aitm-banner-image';

const style = (theme) => ({
  archiveBg: {
    background: '#f9fcff',
  },
});

const millsBerryDomains = [
  'millsberry.com.np',
  'millsberry.com.np',
  'millsberry.edu.np',
];

const Detail = ({
  base_slug,
  data,
  resource_path,
  theme,
  classes,
  match,
  contentKey,
}) => {
  const mainData = data[0];
  const isRpSharma = theme.domain === 'rajendraphuyal.com.np';
  const isBannerImage =
    theme.domain === 'lna.edu.np' ||
    theme.domain === 'aitm.edu.np' ||
    theme.domain === 'elixir.edu.np' ||
    theme.domain === 'nobel.edu.np' ||
    theme.domain === 'spa.edu.np';
  const khwopaCollege = theme.domain === 'journal.khwopacollege.edu.np';
  const journalSxc = theme.domain === 'journal.sxc.edu.np';
  const isSxc = theme.domain === 'sxc.edu.np';
  const journalCurrentIssue =
    (journalSxc || khwopaCollege) && match.url === '/current-issue';
  const journalArchive =
    (journalSxc || khwopaCollege) && match.url === '/archive';
  const isMillsBerry = millsBerryDomains.includes(theme.domain);
  const careerVacancies = contentKey === 'career';
  const omegaCollege = theme.domain === 'omegacollege.edu.np';
  const spaCollege = theme.domain === 'spa.edu.np';
  return (
    <>
      {isMillsBerry || isRpSharma || isBannerImage ? (
        <>
          {isMillsBerry || isRpSharma ? (
            mainData.image ? (
              <MillsberryClzDetailPage image={resource_path + mainData.image} />
            ) : theme.banner ? (
              <MillsberryClzDetailPage
                image={theme.resourcePath + 'banner/' + theme.banner}
              />
            ) : null
          ) : isRpSharma ? (
            <RpsharmaDetailPage
              image={theme.resourcePath + 'banner/' + theme.banner}
              title='Blogs'
            />
          ) : (
            <AitmBannerImage
              image={theme.resourcePath + 'banner/' + theme.banner}
            />
          )}
        </>
      ) : null}
      <div
        className={`${
          journalCurrentIssue ? `${classes.archiveBg}` : 'container-fluid'
        }`}
      >
        <div className='single-block'>
          {/*<PageTitle title={mainData.title} />*/}
          {journalCurrentIssue || journalArchive || careerVacancies ? (
            <>
              {journalCurrentIssue || journalArchive ? (
                <>
                  {journalArchive ? (
                    <ArchiveList
                      data={data}
                      resourcePath={resource_path}
                      base_slug={base_slug}
                    />
                  ) : (
                    <CurrentIssue
                      data={data}
                      resourcePath={resource_path}
                      base_slug={base_slug}
                    />
                  )}
                </>
              ) : (
                <>
                  {isMillsBerry || isSxc ? (
                    isSxc ? (
                      <CareerVacancies5 data={data} />
                    ) : (
                      <CareerVacancies3 data={data} />
                    )
                  ) : (
                    <CareerVacancies2 data={data} />
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {data.map((item, index) => {
                return (
                  <div key={index} className='list-page-item'>
                    <div className='row '>
                      <div className='col-lg-2 col-md-12 col-sm-12 max-content'>
                        {item.external_link ? (
                          <a
                            target='_blank'
                            rel='noopener noreferrer'
                            href={item.external_link}
                          >
                            <div className='fixed-image cover fixed-image-holder'>
                              {item.image ? (
                                <img
                                  src={resource_path + item.image}
                                  alt={item.title}
                                />
                              ) : null}
                            </div>
                          </a>
                        ) : (
                          <Link
                            to={
                              base_slug + `${spaCollege ? item?.id : item.slug}`
                            }
                          >
                            <div className='fixed-image cover fixed-image-holder'>
                              {item.image ? (
                                <img
                                  src={resource_path + item.image}
                                  alt={item.title}
                                />
                              ) : null}
                            </div>
                          </Link>
                        )}
                      </div>
                      <div className='col-lg-10 col-md-12 col-sm-12'>
                        <h4>
                          {item.external_link ? (
                            <a
                              target='_blank'
                              rel='noopener noreferrer'
                              href={item.external_link}
                            >
                              {item.title}
                            </a>
                          ) : (
                            <Link
                              to={
                                base_slug +
                                `${spaCollege ? item?.id : item.slug}`
                              }
                            >
                              {item.title}
                            </Link>
                          )}
                          {item.started_date ? (
                            <span className='date'>
                              <FA name='calendar' />
                              {item.started_date}
                            </span>
                          ) : null}
                        </h4>
                        {omegaCollege ? (
                          <div>
                            {item.description
                              ? HTMLReactParser(item.description)
                              : null}
                          </div>
                        ) : (
                          <div>
                            {item.summary
                              ? item.summary.substring(0, 300)
                              : null}
                          </div>
                        )}
                        {omegaCollege ? (
                          <div>
                            {item.external_link ? (
                              <a
                                target='_blank'
                                href={item.external_link}
                                rel='noreferrer'
                              >
                                View Answer
                              </a>
                            ) : (
                              <Link to={base_slug + item.slug}>
                                View Answer
                              </Link>
                            )}
                          </div>
                        ) : (
                          <div>
                            {item.external_link ? (
                              <a
                                target='_blank'
                                href={item.external_link}
                                rel='noreferrer'
                              >
                                View Detail...
                              </a>
                            ) : (
                              <Link
                                to={
                                  base_slug +
                                  `${spaCollege ? item?.id : item.slug}`
                                }
                              >
                                View Detail...
                              </Link>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default injectSheet(style)(Detail);
