import React from 'react';
import injectSheet from 'react-jss';
import { Link } from 'react-router-dom';
import { ListGroup, ListGroupItem } from 'reactstrap';

// image
import Image from 'images/bg-img06.jpg';

// asset import
import './index.css';

const styles = (theme) => ({
  sidebarListTitle: {
    background: theme.colors.primary,
    borderRadius: '15px 15px 0px 0px !important',
    marginTop: '-90px',
    fontWeight: 'bold',
    color: '#fff',
    fontSize: '17px',
    boxShadow:
      '0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12)',
  },
  sideBarNavMain: {
    position: 'relative',
    '& .sidebar-list-title': {
      fontWeight: '700',
      color: '#f8de01',
      fontSize: '18px',
      textTransform: 'uppercase',
      marginBottom: '20px',
    },
    '& .list-group-item': {
      background: 'transparent',
      color: '#fff',
      borderRadius: '0',
      border: 'none',
      fontSize: '16px',
      transition: 'all .3s ease-out',
      padding: '0 0 15px',
      '&:hover': {
        color: theme.colors.secondary,
      },
    },
  },
  activeList1: {
    overflow: 'auto',
  },
  activeList: {
    overflow: 'auto',
    boxShadow: '0px 15px 30px rgb(42 67 113 / 15%)',
    borderRadius: '0 0 15px 15px',
    '&>.active': {
      backgroundColor: theme.colors.secondary,
      border: 'none',
    },
    '& .list-group': {
      '& a': {
        borderBottom: '1px solid #d6cece80',
      },
    },
    '& .list-group-item': {
      border: 'none',
      borderBottom: '1px solid rgb(214 206 206 / 50%)',
      zIndex: 0,
      '&:first-child': {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      },
      '&:last-child': {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderBottom: 'none',
      },
    },
  },
  newsListBg: {
    marginTop: '30px',
    boxShadow: '0px 15px 30px rgb(42 67 113 / 15%)',
    borderRadius: '0 0 15px 15px',
    '& .news-detail-title': {
      background: `${theme.colors.primary}`,
      padding: '10px',
      borderRadius: '15px 15px 0 0',
      color: '#fff',
      fontSize: '17px',
      fontWeight: 'bold',
    },
    '& .news-list': {
      padding: '15px 10px',
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      '&::before': {
        content: "''",
        position: 'absolute',
        height: '1px',
        width: '100%',
        background: '#dadada',
        bottom: 0,
        left: 0,
      },
      '&:last-child': {
        '&::before': {
          display: 'none',
        },
      },
      '& .img-holder': {
        height: '100%',
        paddingRight: '15px',
        '& .bg-stretch': {
          height: '50px',
          width: '50px',
          borderRadius: '50%',
        },
      },
      '& .content': {
        overflow: 'hidden',
        width: '100%',
        '& .news-title': {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          width: '100%',
          '& a': {
            color: `${theme.colors.primary}`,
            fontSize: '16px',
            textDecoration: 'none',
            transition: 'all .3s ease-out',
            '&:hover': {
              color: `${theme.colors.secondary}`,
            },
          },
        },
        '& .description': {
          margin: '0',
          padding: '0',
          color: '#73738c',
          fontSize: '14px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          width: '100%',
        },
      },
    },
  },
  newsListBg2: {
    marginTop: '30px',
    position: 'relative',
    '&::before': {
      content: "''",
      position: 'absolute',
      height: '100%',
      width: '100%',
      background: `${theme.colors.primary}`,
      opacity: '0.5',
    },
    '& .content': {
      position: 'relative',
      zIndex: 9,
      padding: '50px 30px',
      '& .description': {
        padding: '0',
        color: '#fff',
        fontSize: '18px',
        marginBottom: '10px',
        lineHeight: '2',
      },
      '& span': {
        color: '#fff',
        fontSize: '22px',
        letterSpacing: '1px',
      },
    },
  },
});

const millsBerryDomains = ['millsberry.com.np', 'millsberry.edu.np'];

const SidebarNav1 = ({
  title,
  base_slug,
  relatedData,
  classes,
  match,
  theme,
  spa,
}) => {
  const hostname =
    (window.location.hostname.indexOf('www.') && window.location.hostname) ||
    window.location.hostname.replace('www.', '');
  const MMIHS = hostname === 'mmihs.edu.np';
  const sidebarData = relatedData.notice || relatedData.event;
  return (
    <>
      {millsBerryDomains.includes(theme.domain) ? (
        <>
          <div className={`${classes.sideBarNavMain}`}>
            <div className='sidebar-list-title'>{title}</div>
            {relatedData.data && relatedData.data.length && (
              <ListGroup
                className={`sidebar-list-group ${classes.activeList1}`}
              >
                {relatedData.data.map((realtedItem, i) =>
                  realtedItem.external_link ? (
                    <ListGroupItem
                      key={realtedItem.slug + i}
                      active={
                        realtedItem.slug ===
                        (match.params.slugMenuLink
                          ? match.params.slugMenuLink
                          : match.params.slugSubMenu)
                      }
                      target='_blank'
                      tag={'a'}
                      href={realtedItem.external_link}
                      action
                    >
                      {realtedItem.title}
                    </ListGroupItem>
                  ) : (
                    <ListGroupItem
                      key={realtedItem.slug + i}
                      active={
                        realtedItem.slug ===
                        (match.params.slugMenuLink
                          ? match.params.slugMenuLink
                          : match.params.slugSubMenu)
                      }
                      tag={Link}
                      to={base_slug + realtedItem.slug}
                      action
                    >
                      {realtedItem.title}
                    </ListGroupItem>
                  )
                )}
              </ListGroup>
            )}
          </div>
        </>
      ) : (
        <>
          <div className={`${classes.sidebarListTitle} list-group-item`}>
            {title}
          </div>
          {relatedData.data && relatedData.data.length && (
            <ListGroup
              className={`sidebar-list-group sidebar-list-title ${classes.activeList}`}
            >
              {relatedData.data.map((realtedItem, i) => {
                return (
                  <React.Fragment key={i + '__'}>
                    {realtedItem.external_link ? (
                      <ListGroupItem
                        key={realtedItem.slug + i}
                        active={
                          realtedItem.slug ===
                          (match.params.slugMenuLink
                            ? match.params.slugMenuLink
                            : match.params.slugSubMenu)
                        }
                        target='_blank'
                        tag={'a'}
                        href={realtedItem.external_link}
                        action
                      >
                        {realtedItem.title}
                      </ListGroupItem>
                    ) : (
                      <ListGroupItem
                        key={realtedItem.slug + i}
                        active={
                          spa
                            ? realtedItem.id
                              ? realtedItem.id ===
                                (match.params.slugMenuLink
                                  ? match.params.slugMenuLink
                                  : match.params.slugSubMenu)
                              : realtedItem.slug ===
                                (match.params.slugMenuLink
                                  ? match.params.slugMenuLink
                                  : match.params.slugSubMenu)
                            : realtedItem.slug ===
                              (match.params.slugMenuLink
                                ? match.params.slugMenuLink
                                : match.params.slugSubMenu)
                        }
                        tag={Link}
                        to={
                          base_slug +
                          `${spa ? (realtedItem.id ? realtedItem.id : realtedItem.slug) : realtedItem.slug}`
                        }
                        action
                      >
                        {realtedItem.title}
                      </ListGroupItem>
                    )}
                  </React.Fragment>
                );
              })}
            </ListGroup>
          )}
          {MMIHS ? (
            <>
              {sidebarData && sidebarData.data && sidebarData.data.length && (
                <div className={`${classes.newsListBg}`}>
                  <div className='news-detail-title'>{sidebarData.title}</div>
                  {sidebarData.data.map((item, i) => {
                    if (i > 2) return;
                    return (
                      <div className='news-list' key={item.slug}>
                        <div className='img-holder'>
                          <div
                            className='bg-stretch background-image cover'
                            style={{
                              backgroundImage: item.image
                                ? `url(${sidebarData.resource_path + item.image})`
                                : `url(${Image})`,
                            }}
                          ></div>
                        </div>
                        <div className='content'>
                          <div className='news-title'>
                            <Link to={sidebarData.base_slug + item.slug}>
                              {item.title}
                            </Link>
                          </div>
                          <p className='description'>{item.summary}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </>
          ) : null}
        </>
      )}
    </>
  );
};

export default injectSheet(styles)(SidebarNav1);
