import React from 'react';

// material ui
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

// react jss
import injectSheet from 'react-jss';

// style
const style = (theme) => ({
  root: {
    table: {
      minWidth: 700,
    },
  },
  currentIssueBg: {
    // padding: "100px 0",
  },
  currentIssueMain: {
    '& .content-holder': {
      '& .text-holder': {
        marginBottom: '50px',
        '& .title': {
          fontSize: '32px',
          fontWeight: 700,
          color: '#434343',
          position: 'relative',
          paddingBottom: '5px',
          '&::before': {
            content: "''",
            position: 'absolute',
            bottom: 0,
            height: '2px',
            width: '60px',
            background: theme.colors.secondary,
          },
        },
      },
      '& .MuiTableCell-root': {
        fontFamily: 'unset',
        borderBottom: 'none',
      },
      '& .MuiTableRow-head': {
        borderBottom: '1px solid #eee',
        '& .MuiTableCell-head': {
          fontWeight: 700,
          color: '#434343',
          borderRight: '1px solid #eee',
          '&:last-child': {
            borderRight: 'none',
          },
        },
      },
      '& .MuiTableBody-root': {
        '& .MuiTableRow-root': {
          borderBottom: '1px solid #eee',
          '&:last-child': {
            borderBottom: 'none',
          },
          '& .MuiTableCell-body': {
            borderRight: '1px solid #eee',
            color: '#73738c',
            fontSize: '16px',
            '&:last-child': {
              borderRight: 'none',
            },
          },
        },
      },
      '& .MuiPaper-root': {
        boxShadow: '0 10px 50px rgb(166 209 237 / 20%)',
        borderRadius: '15px',
      },
      '& .img-holder': {
        height: '100px',
        width: '150px',
        margin: '0 auto',
        '& .bg-stretch': {
          height: '100%',
          width: '100%',
        },
      },
      '& .btn-success': {
        fontSize: '14px',
        padding: '5px 10px',
        borderRadius: '30px',
        boxShadow: '0 10px 50px rgb(40 167 69 / 30%)',
      },
      '& .btn-danger': {
        fontSize: '14px',
        padding: '5px 10px',
        borderRadius: '30px',
        boxShadow: `0 10px 50px ${theme.colors.secondary}4f`,
        background: theme.colors.secondary,
        border: `1px solid ${theme.colors.secondary}`,
      },
    },
  },
});

const CurrentIssue = ({ classes, data, resourcePath }) => {
  const currentIssueData = data.filter((archive) => {
    return Number(archive.archived) === 0;
  });
  return (
    <>
      {currentIssueData && currentIssueData.length ? (
        <section className={`${classes.currentIssueBg}`}>
          <div className={`container-fluid ${classes.currentIssueMain}`}>
            <div className='content-holder'>
              <div className='text-holder'>
                <h2 className='title'>Current Issue</h2>
              </div>
              <TableContainer component={Paper} className={`${classes.root}`}>
                <Table
                  className={`${classes.table}`}
                  aria-label='customized table'
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Title</TableCell>
                      <TableCell>Author Name</TableCell>
                      <TableCell style={{ width: '200px' }}>File</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentIssueData.map((item, i) => {
                      return (
                        <TableRow key={i}>
                          <TableCell>{item.title}</TableCell>
                          <TableCell>{item.name}</TableCell>
                          <TableCell>
                            {item.file ? (
                              <a
                                href={resourcePath + item.file}
                                download={item.file}
                                className='btn btn-danger'
                                target='_blank'
                                rel='noreferrer'
                              >
                                Click Here to Download
                              </a>
                            ) : null}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </section>
      ) : null}
    </>
  );
};

export default injectSheet(style)(CurrentIssue);
