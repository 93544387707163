import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const showSuccess = (msg) => {
  toast.success(msg);
};

const showWarning = (msg) => {
  toast.warn(msg);
};

const showInfo = (msg) => {
  toast.info(msg);
};

const showError = (errMsg) => {
  toast.error(errMsg);
};

const handleError = (err) => {
  const error = err.res.data;
  if (typeof err.msg === 'string') {
    showError(error.msg);
  }
};

export default {
  showSuccess,
  showWarning,
  showInfo,
  showError,
  handleError,
};
