import TagManager from 'react-gtm-module';

const GoogleAnalytics = (props) => {
  const tagManagerArgs = {
    gtmId: props.id,
  };

  TagManager.initialize(tagManagerArgs);
  return null;
};

export default GoogleAnalytics;
