import React from 'react';
import HashLoader from 'react-spinners/HashLoader';

// react jss
import injectSheet from 'react-jss';

const style = (theme) => ({
  Loader: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
const Loader4 = ({ classes }) => {
  return (
    <div className='container'>
      <section id='loader'>
        <div className={`loader ${classes.Loader}`}>
          <HashLoader size={80} color={'#01aae4'} />
        </div>
      </section>
    </div>
  );
};

export default injectSheet(style)(Loader4);
