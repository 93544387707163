import React from 'react';

// react-jss
import injectSheet from 'react-jss';

// asset import
import './index.css';
// custom styles
const styles = {
  pageHeader: {
    height: (props) => (props.height ? props.height : '45vh'),
    backgroundImage: (props) => `url("${props.image}")`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  pageHeader2: {
    height: (props) => (props.height ? props.height : '100vh'),
    backgroundImage: (props) => `url("${props.image}")`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
};

const PageHeader = (props) => {
  const { classes, profile } = props;
  const isPabson = profile.data.domain === 'pabson.org.np';
  return (
    <div
      className={`${isPabson ? `${classes.pageHeader2}` : `${classes.pageHeader}`}`}
    />
  );
};

export default injectSheet(styles)(PageHeader);
