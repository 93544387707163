import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import NProgress from 'nprogress';

import 'nprogress/nprogress.css';
NProgress.configure({ easing: 'ease', speed: 500, showSpinner: false });

function RouteWithSubRoutes(route) {
  //   let subRoutes = null;
  return (
    <React.Fragment>
      <Route
        exact={route.exact}
        path={route.path}
        component={route.component}
      />
      {/* {route.children.length
        ? route.children.map((subroute, i) => (
            <Route
              key={i}
              path={subroute.path}
              component={subroute.component}
            />
          ))
        : null} */}
    </React.Fragment>
  );
}

class ApiRoute extends Component {
  UNSAFE_componentWillMount() {
    NProgress.start();
  }

  componentDidMount() {
    NProgress.done();
  }

  render() {
    return <RouteWithSubRoutes {...this.props} />;
  }
}

export default ApiRoute;
