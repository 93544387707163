import React, { Component } from 'react';
import RenderTheme from 'utils/RenderTheme';
import { connect } from 'react-redux';

//component import
import Loader from 'components/loader/Loader';

const FallBack = () => (
  <div
    className='async-block'
    style={{ minHeight: '40vh', backgroundColor: '#f9f9f9' }}
  >
    <Loader />
  </div>
);

class RenderBlocks extends Component {
  render() {
    const { data, match, layout, websiteData, contentKey } = this.props;
    const defaultBlockProps = {
      ...websiteData,
      match: match,
      contentKey: contentKey,
      menu: layout.menu,
      profile: layout.profile,
      socialMedia: layout.socialMedia,
      usefulLink: layout.usefulLink,
    };

    return (
      <>
        {data.isLoaded ? (
          <React.Suspense fallback={<FallBack />}>
            {data.blocks.length ? (
              data.blocks.map((block, i) => {
                let themeId = null;
                let blockProps;

                if (!block.theme && !block.page) return null;

                if (block.page && !block.theme) {
                  themeId = 'ContentBlock1';
                } else {
                  themeId = block.theme.theme_id;
                }

                // render custom data for theme
                // if (block.theme.model === null) {
                //     blockProps = {
                //         block: {
                //             resource_path: "https://smartschool.edigitalnepal.edu.np/uploads/template/",
                //             data: {
                //                 title: block.title,
                //                 description: block.description,
                //                 image: block.image,
                //                 additional_content: block.additional_content
                //             },
                //             base_slug: block.base_slug,
                //         },
                //         profile: layout.profile,
                //         themeId: themeId
                //     };
                // } else {
                //     blockProps = {
                //         ...defaultBlockProps,
                //         themeId: themeId
                //     };
                // }
                if (block.page || block.additional_content) {
                  const contentData = block.page || block.additional_content;
                  blockProps = {
                    ...defaultBlockProps,
                    block: {
                      resource_path: block.resource_path,
                      data: contentData,
                      base_slug: block.base_slug,
                    },
                    profile: layout.profile,
                    themeId: themeId,
                  };
                  // console.log(themeId, blockProps,":::");
                } else {
                  blockProps = {
                    ...defaultBlockProps,
                    themeId: themeId,
                  };
                }
                return <RenderTheme key={themeId + '_' + i} {...blockProps} />;
              })
            ) : (
              <RenderTheme themeId={data.defaultTheme} {...defaultBlockProps} />
            )}
          </React.Suspense>
        ) : (
          <FallBack />
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    layout: state.layout,
    websiteData: state.websiteData,
  };
}

export default connect(mapStateToProps)(RenderBlocks);
