import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, FormGroup, Label, Input, Button } from 'reactstrap';

// notification
import { ToastContainer } from 'react-toastify';
import toast from '../../notification/Notify';

// react spin loader
import Loader from 'react-loader-spinner';

// material icon
import InsertEmoticonRoundedIcon from '@material-ui/icons/InsertEmoticonRounded';
import MoodBadRoundedIcon from '@material-ui/icons/MoodBadRounded';

//action import
import { sendContactMessage } from '../../../actions/frontAction';

// react jss
import injectSheet from 'react-jss';

// theme
const style = (theme) => ({
  feedBackFormBg: {
    padding: '50px 0',
  },
  feedBackFormMain: {
    '& .content-holder': {
      boxShadow: '8px 8px 30px 0px rgb(42 67 113 / 15%)',
      padding: '30px',
      borderRadius: '15px',
      maxWidth: '700px',
      margin: '0 auto',
      position: 'relative',
      '& .text-holder': {
        marginBottom: '30px',
        textAlign: 'center',
        '& .title': {
          fontSize: '32px',
          fontWeight: 700,
          letterSpacing: '2px',
          color: '#0d9a0d',
        },
        '& .icon-holder': {
          position: 'relative',
          display: 'inline-block',
          '&::before': {
            content: "''",
            position: 'absolute',
            top: '50%',
            left: '80px',
            transform: 'translate(0px, -50%)',
            height: '1px',
            width: '200px',
            background: theme.colors.secondary,
          },
          '&::after': {
            content: "''",
            position: 'absolute',
            top: '50%',
            right: '80px',
            transform: 'translate(0px, -50%)',
            height: '1px',
            width: '200px',
            background: theme.colors.secondary,
          },
          '& .MuiSvgIcon-root': {
            fill: theme.colors.secondary,
            fontSize: '32px',
          },
        },
      },
      '& .btn-outline-primary': {
        background: '#0d9a0d',
        border: '2px solid #0d9a0d',
        color: '#fff',
        fontSize: '18px',
        fontWeight: 500,
        letterSpacing: '2px',
        minWidth: '120px',
        boxShadow: 'rgb(13 154 13 / 30%) 8px 8px 30px 0px',
        transition: 'all .3s ease-out',
        '&:hover': {
          background: theme.colors.secondary,
          boxShadow: 'rgb(247 148 30 / 30%) 8px 8px 30px 0px',
          border: `2px solid ${theme.colors.secondary}`,
        },
      },
    },
    '@media(max-width: 600px)': {
      '& .content-holder': {
        '& .text-holder': {
          '& .icon-holder': {
            '&::before': {
              display: 'none',
            },
            '&::after': {
              display: 'none',
            },
          },
        },
      },
    },
  },
  '@media(max-width: 600px)': {
    feedBackFormBg: {
      marginTop: '50px',
    },
  },
  '@media(min-width: 768px)': {
    feedBackFormBg: {
      marginTop: '100px',
    },
  },
  '@media(min-width: 992px)': {
    feedBackFormBg: {
      marginTop: '0',
    },
  },
});

const DefaultForm = {
  name: '',
  email: '',
  phone: '',
  message: '',
  subject: 'Feedback Message',
  formType: 'feedback',
};

class FeedbackForm2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        ...DefaultForm,
      },
      error: {
        ...DefaultForm,
      },
      validForm: false,
      isSubmitting: false,
    };
  }
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState(
      (perviousState) => ({
        data: {
          ...perviousState.data,
          [name]: value,
        },
      }),
      () => {
        this.validateErrors(name);
      }
    );
  };

  validateErrors(fieldName) {
    let error;
    switch (fieldName) {
      case 'name':
        error = this.state.data[fieldName].trim().length
          ? ''
          : 'Name is Required';
        break;

      case 'email':
        if (this.state.data[fieldName].trim().length) {
          error = this.state.data.email.includes('@') ? '' : 'Email is Invalid';
        } else {
          error = 'Email is Required';
        }
        break;

      case 'phone':
        if (this.state.data[fieldName].trim().length) {
          error = this.state.data.phone ? '' : 'Phone is Invalid';
        } else {
          error = 'Phone Is Required';
        }
        break;

      case 'message':
        error = this.state.data[fieldName].trim().length
          ? ''
          : 'Message is Required';
        break;
      case 'formType':
        error = this.state.data[fieldName].trim().length
          ? ''
          : 'Feedback is Required';
        break;
      case 'subject':
        error = this.state.data[fieldName].trim().length
          ? ''
          : 'Subject is Required';
        break;

      default:
        break;
    }
    this.setState((perviousState) => ({
      error: {
        ...perviousState.error,
        [fieldName]: error,
      },
    }));
    return error.length == 0;
  }

  validateForm() {
    let isValid = true;
    Object.keys(this.state.data).map((key) => {
      let isValidStatus = this.validateErrors(key);
      if (isValid) {
        isValid = isValidStatus;
      }
    });
    return isValid;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      this.setState({
        isSubmitting: true,
      });
      this.props
        .sendContactMessage(this.state.data)
        .then((response) => {
          if (response.success) {
            toast.showSuccess(`Message Sent SuccessFully`);
            this.setState({
              data: DefaultForm,
            });
          } else {
            toast.showError(
              response.message ? response.message : 'Sent Failed'
            );
          }
          this.setState({
            isSubmitting: false,
          });
        })
        .catch((err) => {
          toast.showError('Something went wrong when sending Message!');
          this.setState({
            isSubmitting: false,
          });
        });
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <section className={`${classes.feedBackFormBg}`}>
        <div className={`container-fluid ${classes.feedBackFormMain}`}>
          <div className='content-holder'>
            <div className='text-holder'>
              <h2 className='title'>Your feedback helps us improve</h2>
              <div className='icon-holder'>
                <InsertEmoticonRoundedIcon />
                <MoodBadRoundedIcon />
              </div>
            </div>
            <Form noValidate onSubmit={this.handleSubmit}>
              <FormGroup>
                <Label for='name'>Name: </Label>
                <Input
                  value={this.state.data.name}
                  onChange={this.handleChange}
                  type='text'
                  name='name'
                  id='name'
                  placeholder='Enter Your Name'
                />
                <p style={{ color: '#dc3545', margin: '0', padding: '0' }}>
                  {this.state.error.name}
                </p>
              </FormGroup>
              <FormGroup>
                <Label for='email'>Email</Label>
                <Input
                  value={this.state.data.email}
                  onChange={this.handleChange}
                  type='email'
                  name='email'
                  id='email'
                  placeholder='Enter Your Email'
                />
                <p style={{ color: '#dc3545', margin: '0', padding: '0' }}>
                  {this.state.error.email}
                </p>
              </FormGroup>
              <FormGroup>
                <Label for='phone'>Mobile No: </Label>
                <Input
                  value={this.state.data.phone}
                  onChange={this.handleChange}
                  type='text'
                  name='phone'
                  id='phone'
                  placeholder='Enter Your Phone Number'
                />
                <p style={{ color: '#dc3545', margin: '0', padding: '0' }}>
                  {this.state.error.phone}
                </p>
              </FormGroup>
              <FormGroup>
                <Label for='message'>Message: </Label>
                <Input
                  value={this.state.data.message}
                  onChange={this.handleChange}
                  type='textarea'
                  name='message'
                  id='message'
                  placeholder='Enter Your Message'
                />
                <p style={{ color: '#dc3545', margin: '0', padding: '0' }}>
                  {this.state.error.message}
                </p>
              </FormGroup>
              <FormGroup>
                <Input
                  value={this.state.data.formType}
                  onChange={this.handleChange}
                  type='textarea'
                  name='formType'
                  id='formType'
                  placeholder='Enter Your FeedBack'
                  hidden
                />
              </FormGroup>
              <FormGroup>
                <Input
                  value={this.state.data.subject}
                  onChange={this.handleChange}
                  type='textarea'
                  name='subject'
                  id='subject'
                  placeholder='Enter Your Subject'
                  hidden
                />
              </FormGroup>
              {this.state.isSubmitting ? (
                <Button
                  className='btn btn-outline-primary rounded-pill'
                  type='submit'
                  disabled={!this.state.validForm}
                  calue='submit'
                  onClick={this.toast}
                >
                  <Loader
                    type='Puff'
                    color='#fff'
                    height={30}
                    width={30}
                    timeout={3000} //3 secs
                  />
                </Button>
              ) : (
                <Button
                  className='btn btn-outline-primary rounded-pill'
                  type='submit'
                  calue='submit'
                  onClick={this.toast}
                  typeof='feedback'
                >
                  Submit
                </Button>
              )}
            </Form>
            <ToastContainer />
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    program: state.websiteData.program,
  };
}

export default connect(mapStateToProps, { sendContactMessage })(
  injectSheet(style)(FeedbackForm2)
);
