import React, { useEffect } from 'react';
import { Box, Button, Container, Grid, styled } from '@material-ui/core';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';

const StyledSXCIndex = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100vh',
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 2000,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#1d184e',
  overflow: 'auto',
  '& .course-item': {
    backgroundColor: '#fff',
    minHeight: '300px',
    borderRadius: '10px',
    position: 'relative',
    overflow: 'hidden',
    backgroundSize: 'auto 100%',
    transition: 'all 1s ease-out',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'end',
    textAlign: 'center',
    color: '#fff',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: '#00c6fbad',
      opacity: 0.65,
    },
    '&:nth-child(2)': {
      '&::before': {
        backgroundColor: '#f1ab2dad',
      },
    },
    '&:hover': {
      backgroundSize: 'auto 120%',
    },
    '& .content': {
      position: 'relative',
      zIndex: 1,
      '& h2': {
        fontSize: '22px',
      },
      '& .button': {
        color: '#fff',
        borderColor: '#fff',
        borderRadius: '200px',
        padding: '7px 22px',
        '&:hover': {
          borderColor: '#00c6fb',
          background: '#00c6fbb8',
        },
      },
    },
  },
  '& .course-item-grid:nth-child(2) .course-item': {
    '&::before': {
      backgroundColor: '#f1ab2dad',
    },
    '& .content': {
      '& .button': {
        borderColor: '#fff',
        '&:hover': {
          borderColor: '#f0ae1a',
          background: '#f0ae1ab8',
        },
      },
    },
  },
  [theme.breakpoints.down('sm')]: {
    '& .course-item': {
      backgroundSize: 'cover !important',
    },
  },
  [theme.breakpoints.down('xs')]: {
    '& .course-item': {
      minHeight: '200px',
    },
  },
}));

const courseItems = [
  {
    title: 'SXC',
    image:
      'https://sxc.edu.np/uploads/program/_MG_6049-file-20230828100000-448.JPG',
    link: '',
  },
  {
    title: 'A Level',
    image:
      'https://sxc.edu.np/uploads/program/alevel1-file-20220407051243-27.JPG',
    link: 'https://loyola.sxc.edu.np/',
  },
  {
    title: 'Plus 2',
    image:
      'https://neb.sxc.edu.np/uploads/gallery/_MG_5476-file-20230815131843-223.JPG',
    link: 'https://neb.sxc.edu.np/',
  },
];

const SXCIndex = ({ profile, onCloseHomeScreen }) => {
  useEffect(() => {
    document.body.classList.add('overflow-hidden');

    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, []);

  return (
    <StyledSXCIndex>
      <Box flexGrow={1} margin={'auto'}>
        <Container>
          <Box py={4}>
            <Box mb={3} textAlign={'center'}>
              <img
                className='img-fluid'
                src={profile.resource_path + profile.data.logo}
                alt={profile.data.name}
              />
            </Box>
            <Box>
              <Grid container spacing={3}>
                {courseItems.map((item, i) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      md={4}
                      key={`course_item_${i}`}
                      className='course-item-grid'
                    >
                      <Box
                        p={4}
                        className='course-item background-image cover'
                        style={{ backgroundImage: `url(${item.image})` }}
                      >
                        <div className='content'>
                          <h2 style={{ marginBottom: '15px' }}>{item.title}</h2>
                          {item.link ? (
                            <Button
                              className='button'
                              variant='outlined'
                              component='a'
                              href={item.link}
                              endIcon={<ArrowForwardRoundedIcon />}
                            >
                              Enter
                            </Button>
                          ) : (
                            <Button
                              className='button'
                              variant='outlined'
                              endIcon={<ArrowForwardRoundedIcon />}
                              onClick={onCloseHomeScreen}
                            >
                              Enter
                            </Button>
                          )}
                        </div>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Box>
        </Container>
      </Box>
    </StyledSXCIndex>
  );
};

export default SXCIndex;
