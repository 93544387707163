import React from 'react';
import { BreadcrumbItem } from 'reactstrap';
import { Link } from 'react-router-dom';

// theming
import injectSheet from 'react-jss';

// asset import
import './index.css';

const style = (theme) => ({
  breadCrumbLink: {
    color: theme.colors.primary,
  },
});

function getBreadCrumbMenuList(menu, params) {
  let breadCrumbMenuList = menu[0].slug == '' ? [menu[0]] : [];
  let activeSlug = params.slugMenuLink
    ? params.slugMenuLink
    : params.slugSubMenu
      ? params.slugSubMenu
      : params.slugMenu;

  menu.map((m) => {
    if (m.slug === params.slugMenu) {
      let breadItem = { label: m.label, slug: m.slug };
      if (m.slug === activeSlug) {
        breadItem.active = true;
      }
      breadCrumbMenuList.push(breadItem);

      m.submenu &&
        m.submenu.map((s) => {
          if (params.slugSubMenu && s.slug === params.slugSubMenu) {
            let breadItem = { label: s.label, slug: m.slug + '/' + s.slug };
            if (s.slug === activeSlug) {
              breadItem.active = true;
            }
            breadCrumbMenuList.push(breadItem);

            s.menuLine &&
              s.menuLine.map((mL) => {
                if (params.slugMenuLink && mL.slug === params.slugMenuLink) {
                  let breadItem = { label: mL.label };
                  if (mL.slug === activeSlug) {
                    breadItem.active = true;
                  }
                  breadCrumbMenuList.push(breadItem);
                }
              });
          }
        });
    }
  });

  if (breadCrumbMenuList.length <= 1) {
    breadCrumbMenuList.push({
      label: params.slugMenu.charAt(0).toUpperCase() + params.slugMenu.slice(1),
      slug: params.slugMenu,
    });
  }

  return breadCrumbMenuList;
}

const Breadcrumb = ({ classes, menu, match }) => {
  const breadCrumbMenuList = getBreadCrumbMenuList(menu, match.params);

  return (
    // <nav className="breadcrumb-wrapper">
    //     <div className="container-fluid">
    //         <ol className="breadcrumb">
    //             {breadCrumbMenuList.map((bread, i) => {
    //                 if (bread.active) {
    //                     return <BreadcrumbItem key={bread.slug + i} active>{bread.label}</BreadcrumbItem>;
    //                 } else {
    //                     return <BreadcrumbItem key={bread.slug + i}>
    //                         <Link to={"/" + bread.slug} className={classes.breadCrumbLink}>{bread.label}</Link>
    //                     </BreadcrumbItem>;
    //                 }
    //             })}
    //         </ol>
    //     </div>
    // </nav>
    <></>
  );
};

export default injectSheet(style)(Breadcrumb);
