import React from 'react';
import { Table } from 'reactstrap';
import injectSheet from 'react-jss';
import classNames from 'classnames';

// components import
import PageTitle from 'components/page-title';

// theme
const style = (theme) => ({
  tbody: {
    background: '#f9f9f9',
  },
  btn: {
    fontSize: '12px',
    padding: '4px 10px',
  },
});

const Download1 = ({ title, data, classes, resource_path }) => {
  const hostname =
    (window.location.hostname.indexOf('www.') && window.location.hostname) ||
    window.location.hostname.replace('www.', '');
  const khwopaCollege = hostname === 'khwopacollege.edu.np';
  return (
    <div className='single-block'>
      <div className='container'>
        <PageTitle title={title} />

        <div className='table-box'>
          <Table bordered>
            <thead>
              <tr>
                <th width='40'>S.N.</th>
                <th>Title</th>
                <th width='140'>Image</th>
                <th width='170' />
                <th width='180'>File</th>
              </tr>
            </thead>

            <tbody className={classes.tbody}>
              {data.map((download, i) => {
                let file = download.file || download.image;
                let title = download.title.toLowerCase();
                let hideFields =
                  title === 'Annual reports'.toLowerCase() ||
                  title === 'Audit Reports'.toLowerCase() ||
                  title ===
                    'Journal/Articles (Khwopa Journal Vol4,2021)'.toLowerCase();
                return (
                  <tr key={`${download.slug}_${i}`}>
                    <th>{i + 1}</th>
                    <td>{download.title}</td>
                    <td>
                      {download.image ? (
                        <img
                          src={resource_path + download.image}
                          className='img-fluid'
                          alt='img'
                        />
                      ) : null}
                    </td>
                    <td>
                      {file ? (
                        <a
                          href={resource_path + file}
                          className={classNames([
                            classes.btn,
                            'btn btn-primary',
                          ])}
                          target='_blank'
                        >
                          Click Here to View
                        </a>
                      ) : null}
                    </td>
                    <td>
                      {khwopaCollege && hideFields ? null : (
                        <>
                          {file ? (
                            <a
                              href={resource_path + file}
                              className={classNames([
                                classes.btn,
                                'btn btn-danger',
                              ])}
                              download={file}
                              target='_blank'
                            >
                              Click Here to Download
                            </a>
                          ) : null}
                        </>
                      )}
                      {/*{*/}
                      {/*    file ? (*/}
                      {/*        <a href={resource_path + file}*/}
                      {/*           className={classNames([classes.btn, "btn btn-danger"])}*/}
                      {/*           download={file} target="_blank">*/}
                      {/*            Click Here to Download*/}
                      {/*        </a>*/}
                      {/*    ) : null*/}
                      {/*}*/}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default injectSheet(style)(Download1);
