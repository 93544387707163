import React from 'react';
import Themes from '../themes/ThemeData';

function Theme(props) {
  const Component = Themes[props.themeId];

  if (Component) {
    return <Component {...props} />;
  } else {
    return null;
  }
}

const RenderTheme = (props) => {
  return <Theme {...props} />;
};

export const RenderThemeWithSuspense = (props) => {
  return (
    <React.Suspense fallback={props.fallback ? props.fallback : ''}>
      <Theme {...props} />
    </React.Suspense>
  );
};

export default RenderTheme;
