import React from 'react';
import injectSheet from 'react-jss';

const styles = {
  content: {
    fontFamily:
      '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
};

const ComingSoon = ({ classes, profile }) => {
  return (
    <div
      className={`${classes.content} blank-page-content single-block bg-light-grey text-center`}
    >
      <h1 className='mb-4'>We are Coming Soon!</h1>
      <p>
        The website is under construction.{' '}
        {profile.data.email ? (
          <>
            <br />
            If you have any queries, you can contact us at:{' '}
            <a href={`mailto:${profile.data.email}`}>{profile.data.email}</a>
          </>
        ) : null}
      </p>
    </div>
  );
};

export default injectSheet(styles)(ComingSoon);
