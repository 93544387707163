import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router';
// import FA from "react-fontawesome";

// for scrolling page on button click
// import { animateScroll as scroll } from "react-scroll";

// action import
import { getLayoutData, setLayoutData } from './actions/frontAction';

// for enabling theme object to be passed as HOC
import { ThemeProvider } from 'react-jss';

// asset import
import './app.scss';

// component import
import MainAppNew from './containers/index';
import Loader from './components/loader/Loader';
import Blank from './components/error/Blank';
import ComingSoon from './components/error/ComingSoon';
import Index from 'containers/home/Index';
import Loader22 from './components/loader/22';

let layout = window.layoutData ? window.layoutData.body : null;

class App extends React.Component {
  state = { scrollUpClass: '', newYearLoader: true };
  // isIndex = (window.location.hostname.indexOf('www.') && window.location.hostname || window.location.hostname.replace('www.', '')) === process.env.REACT_APP_URL;
  isIndex = false;

  handleNewYearLoader = () => {
    setTimeout(() => {
      this.setState({ newYearLoader: false });
    }, 4500);
  };

  render() {
    const { theme, profile, isLoaded, underMaintenance } = this.props.layout;
    const millsberry = theme.domain === 'millsberry.edu.np';

    if (isLoaded && millsberry) {
      this.handleNewYearLoader();
    }

    return (
      <ThemeProvider theme={theme}>
        <React.Fragment>
          {this.isIndex ? (
            <Index />
          ) : (
            <>
              {isLoaded ? (
                underMaintenance ? (
                  <ComingSoon profile={profile} />
                ) : (
                  <>
                    {/*{millsberry && this.state.newYearLoader && <Loader22/>}*/}
                    <MainAppNew layout={this.props.layout} />
                  </>
                )
              ) : (
                <Switch>
                  <Route path='/502' component={Blank} />
                  <Route component={() => <Loader type='site-loader' />} />
                </Switch>
              )}
            </>
          )}
        </React.Fragment>
      </ThemeProvider>
    );
  }

  componentDidMount() {
    // window.addEventListener("scroll", this.handleScroll);

    // set layout data from variable
    if (!this.isIndex) {
      layout ? this.props.setLayoutData(layout) : this.props.getLayoutData();
    }
  }

  // componentWillUnmount() {
  //   window.removeEventListener("scroll", this.handleScroll);
  // }

  // handleScroll = event => {
  //   const { pageYOffset } = window;

  //   if (pageYOffset > 400) {
  //     this.setState({ scrollUpClass: "visible" });
  //   } else {
  //     this.setState({ scrollUpClass: "" });
  //   }
  // };

  // scrollToTop() {
  //   scroll.scrollToTop();
  // }
}

function mapStateToProps(state) {
  return {
    layout: state.layout,
  };
}

export default connect(mapStateToProps, { getLayoutData, setLayoutData })(App);
