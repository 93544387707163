import * as types from '../actions/actionTypes';
import initialState from './initialState';

// Website
export const websiteDataReducer = (
  state = initialState.websiteData,
  action
) => {
  if (action.type === types.FETCH_WEBSITE_DATA) {
    return Object.assign({}, state, action.payload);
  } else return state;
};

// Layout
export const layoutReducer = (state = initialState.layout, action) => {
  if (action.type === types.FETCH_LAYOUT_DATA) {
    return Object.assign({}, state, action.payload);
  } else return state;
};

// Home
export const homePageReducer = (state = initialState.homePage, action) => {
  if (action.type === types.FETCH_HOME_DATA) {
    return Object.assign({}, state, action.payload);
  } else return state;
};

// Page
export const pageReducer = (state = initialState.page, action) => {
  if (action.type === types.FETCH_PAGE_DATA)
    return Object.assign({}, state, action.payload);
  else return state;
};

// Key for saving fetched data in store
export const contentReducer = (state = initialState.content, action) => {
  if (action.type === types.FETCH_CONTENT_DATA) {
    let newState = state;
    newState[`${action.payload.url}`] = {
      key: action.payload.key,
      page: action.payload.page,
      data: action.payload.data,
    };
    return newState;
  } else return state;
};

// Photo
export const photoGalleryPageReducer = (
  state = initialState.photoGalleryPage,
  action
) => {
  if (action.type === types.FETCH_PHOTOGALLERY_DATA) {
    return Object.assign({}, state, action.payload);
  } else return state;
};

// Photo List
export const singleGalleryPageReducer = (
  state = initialState.singleGalleryPage,
  action
) => {
  if (action.type === types.FETCH_PHOTOLIST_DATA) {
    return Object.assign({}, state, action.payload);
  } else return state;
};

// Pbl Photo
export const pblPhotoGalleryPageReducer = (
  state = initialState.pblPhotoGalleryPage,
  action
) => {
  if (action.type === types.FETCH_PBL_PHOTOGALLERY_DATA) {
    return Object.assign({}, state, action.payload);
  } else return state;
};

// Pbl Photo List
export const pblSingleGalleryPageReducer = (
  state = initialState.pblSingleGalleryPage,
  action
) => {
  if (action.type === types.FETCH_PBL_PHOTOLIST_DATA) {
    return Object.assign({}, state, action.payload);
  } else return state;
};

// Video
export const videoGalleryPageReducer = (
  state = initialState.videoGalleryPage,
  action
) => {
  if (action.type === types.FETCH_VIDEOGALLERY_DATA) {
    return Object.assign({}, state, action.payload);
  } else return state;
};

// Contact
export const contactPageReducer = (
  state = initialState.contactPage,
  action
) => {
  if (action.type === types.FETCH_CONTACT_DATA) {
    return Object.assign({}, state, action.payload);
  } else return state;
};

// Error
export const errorReducer = (state = initialState.error, action) => {
  if (action.type === types.SET_ERROR)
    return Object.assign({}, state, action.payload);
  else return state;
};
