//theme import
import generateTheme from '../utils/theme';
import defaultTheme from './defaultTheme';

export default {
  content: {},
  websiteData: {
    slider: {
      data: [],
    },
    page: {
      title: '',
      data: [],
    },
    singlePage: {
      title: '',
      data: [],
    },
    notice: {
      title: '',
      data: [],
    },
    singleNotice: {
      title: '',
      data: [],
    },
    event: {
      title: '',
      data: [],
    },
    singleEvent: {
      title: '',
      data: [],
    },
    program: {
      title: '',
      data: [],
    },
    singleProgram: {
      title: '',
      data: [],
    },
    blog: {
      title: '',
      data: [],
    },
    singleBlog: {
      title: '',
      data: [],
    },
    news: {
      title: '',
      data: [],
    },
    singleNews: {
      title: '',
      data: [],
    },
    board: {
      title: '',
      data: [],
    },
    gallery: {
      title: '',
      data: [],
    },
    singleGallery: {
      title: '',
      data: [],
    },
    video: {
      title: '',
      data: [],
    },
    download: {
      title: '',
      data: [],
    },
    singleDownload: {
      title: '',
      data: [],
    },
    testimonial: {
      data: [],
    },
    partner: {
      data: [],
    },
    career: {
      data: [],
    },
    popup: {
      data: [],
    },
    faq: {
      data: [],
    },
    board_message: {
      data: [],
    },
    archive: {
      data: [],
    },
    qa: {
      data: [],
    },
    pblnews: {
      data: [],
    },
    pblgallery: {
      data: [],
    },
  },
  layout: {
    isLoaded: false,
    underMaintenance: false,
    profile: {
      data: {
        favicon: null,
      },
    },
    theme: generateTheme(defaultTheme),
  },
  homePage: {
    blocks: [],
    isLoaded: false,
  },
  page: {
    blocks: [],
    isLoaded: false,
    defaultTheme: 'Page1',
  },
  photoGalleryPage: {
    blocks: [],
    isLoaded: false,
    defaultTheme: 'PhotoGallery1',
  },
  singleGalleryPage: {
    blocks: [],
    isLoaded: false,
    defaultTheme: 'PhotoList1',
  },
  videoGalleryPage: {
    blocks: [],
    isLoaded: false,
    defaultTheme: 'VideoGallery1',
  },
  contactPage: {
    blocks: [],
    isLoaded: false,
    defaultTheme: 'Contact1',
  },
  pblPhotoGalleryPage: {
    blocks: [],
    isLoaded: false,
    defaultTheme: 'PblPhotoGallery',
  },
  pblSingleGalleryPage: {
    blocks: [],
    isLoaded: false,
    defaultTheme: 'PblGalleryDetail',
  },
  contactForm: {
    name: '',
    email: '',
    subject: '',
    message: '',
  },
  error: null,
};
