import React, { Component } from 'react';
import { connect } from 'react-redux';

//action import
import { getPageData, setWebsiteData } from 'actions/frontAction';

//component import
import PageHeader from 'components/page-header';
import Breadcrumb from 'components/breadcrumb';
import RenderBlocks from 'utils/RenderBlocks';

class Page extends Component {
  render() {
    const { profile, content, pageList, match, menu } = this.props;
    let contentKey = null;
    let page = {};

    const contentData = content[match.url];

    if (contentData) {
      contentKey = contentData.key;
      page = pageList[contentData.page];
    }

    const millsBerryDomains = ['millsberry.com.np', 'millsberry.edu.np'];

    const isRpSharma = profile.data.domain === 'rajendraphuyal.com.np';
    const isBannerImage =
      profile.data.domain === 'lna.edu.np' ||
      profile.data.domain === 'spa.edu.np' ||
      profile.data.domain === 'bgmsm.edu.np';

    return (
      <>
        {profile.data.banner &&
        !isRpSharma &&
        !isBannerImage &&
        !millsBerryDomains.includes(profile.data.domain) ? (
          <PageHeader
            image={profile.resource_path + 'banner/' + profile.data.banner}
            profile={profile}
          />
        ) : null}
        <Breadcrumb match={match} menu={menu} />
        <RenderBlocks data={page} match={match} contentKey={contentKey} />
      </>
    );
  }

  getNewPageDate(url) {
    //check if the requested url is already fetched(url is present in content)
    const contentData = this.props.content[url];
    //TODO: quickfix for gallery category, refactor later
    if (contentData && contentData.key !== 'gallery') {
      //update the data for detail page
      if (!contentData.key || contentData.key.startsWith('single')) {
        this.props.setWebsiteData(contentData.data);
      }
    } else {
      this.props.getPageData(url);
    }
  }

  componentDidMount() {
    const url = this.props.match.url;
    this.getNewPageDate(url);
  }

  shouldComponentUpdate(nextProps) {
    const nextUrl = nextProps.match.url;
    if (this.props.match.url !== nextUrl) {
      this.getNewPageDate(nextUrl);
    }
    return true;
  }
}

function mapStateToProps(state) {
  return {
    profile: state.layout.profile,
    menu: state.layout.menu,
    content: state.content,
    pageList: {
      homePage: state.homePage,
      page: state.page,
      photoGalleryPage: state.photoGalleryPage,
      singleGalleryPage: state.singleGalleryPage,
      pblPhotoGalleryPage: state.pblPhotoGalleryPage,
      pblSingleGalleryPage: state.pblSingleGalleryPage,
      videoGalleryPage: state.videoGalleryPage,
      contactPage: state.contactPage,
    },
  };
}

export default connect(mapStateToProps, {
  getPageData,
  setWebsiteData,
})(Page);
