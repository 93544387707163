import React, { Component, useState } from 'react';
import { connect } from 'react-redux';

//action import
import { getHomePageData } from 'actions/frontAction';

//component import
import PopupModal from 'components/modals/index';
import RenderBlocks from 'utils/RenderBlocks';
import MillsberryClzModal from '../../components/millsberry-college/millsberry-clz-modal';
import SXCIndex from './SXCIndex';

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      homeScreenClosed: sessionStorage.getItem('homeScreenClosed'),
    };
  }

  closeHomeScreen = () => {
    sessionStorage.setItem('homeScreenClosed', 'true');
    this.setState({ homeScreenClosed: true });
  };

  render() {
    const { homePage, popup, profile } = this.props;
    const hostname =
      (window.location.hostname.indexOf('www.') && window.location.hostname) ||
      window.location.hostname.replace('www.', '');
    const millsberrySchool = hostname === 'millsberry.com.np';
    const millsberryClz = hostname === 'millsberry.edu.np';
    const isSXC = hostname === 'sxc.edu.np';

    return (
      <>
        {isSXC && !this.state.homeScreenClosed && (
          <SXCIndex
            profile={profile}
            onCloseHomeScreen={this.closeHomeScreen}
          />
        )}
        <RenderBlocks data={homePage} />
        {popup.data.map((item, i) => {
          return (
            item &&
            item.data.map((itemData, i) => {
              return (
                <React.Fragment key={i + '_' + i}>
                  {millsberrySchool || millsberryClz ? (
                    <MillsberryClzModal
                      key={i + '_' + i}
                      resource_path={item.resource_path}
                      item={itemData}
                    />
                  ) : (
                    <PopupModal
                      key={i + '_' + i}
                      resource_path={item.resource_path}
                      itemData={itemData}
                    />
                  )}
                </React.Fragment>
              );
            })
          );
        })}
      </>
    );
  }

  componentDidMount() {
    if (!this.props.homePage.isLoaded) this.props.getHomePageData();
  }
}

function mapStateToProps(state) {
  return {
    profile: state.layout.profile,
    homePage: state.homePage,
    popup: state.websiteData.popup,
  };
}

export default connect(mapStateToProps, {
  getHomePageData,
})(Home);
