import * as React from 'react';
import { styled } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import HTMLReactParser from 'html-react-parser';
import { Button } from '@material-ui/core';
import CloseRounded from '@material-ui/icons/CloseRounded';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: '60px 20px 20px 20px',
    borderTop: 'none',
    borderBottom: 'none',
    border: 'none',
    '& .border-content': {
      padding: '20px',
      border: `2px solid ${theme.palette.primary.main}`,
    },
  },
  '& .MuiPaper-root': {
    margin: '20px 0 ',
    boxShadow: 'none',
    borderRadius: '15px',
    maxWidth: '700px',
    width: '100%',
    overflowY: 'unset',
  },
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
  '& .MuiTypography-root': {
    padding: 0,
  },
  '& .MuiButtonBase-root': {
    color: '#fff',
    background: theme.palette.primary.main,
    boxShadow: 'none',
    height: '30px',
    width: '30px',
    borderRadius: '50%',
    minWidth: 'unset',
    position: 'absolute',
    right: '0px',
    top: '0',
    opacity: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    '& .MuiSvgIcon-root': {
      fontSize: '18px',
    },
    '&:hover': {
      background: theme.palette.secondary.main,
      color: '#fff',
      boxShadow: 'none',
    },
  },
  '& .content': {
    '& .title': {
      fontSize: '24px',
      fontWeight: 700,
      color: '#434343',
      marginBottom: '10px',
    },
    '& .description': {
      fontSize: '18px',
      color: '#73738c',
      lineHeight: 2,
      '& p': {
        margin: 0,
      },
    },
  },
  '& img': {
    width: '100% !important',
  },
}));

const MillsberryClzModal = ({ resource_path, item }) => {
  const [open, setOpen] = React.useState(true);
  const [scroll] = React.useState('body');
  const handleClose = () => {
    setOpen(false);
  };
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const ExternalLink = ({ children }) => {
    return item.external_link ? (
      <a href={item.external_link} target='_blank' rel='noreferrer noopener'>
        {children}
      </a>
    ) : (
      children
    );
  };

  return (
    <div className='popup-bg'>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={open}
        scroll={scroll}
      >
        <DialogContent dividers={scroll === 'body'}>
          <div className='border-content'>
            {item.image && (
              <ExternalLink>
                <img
                  src={resource_path + item.image}
                  alt='img'
                  className='img img-fluid'
                />
              </ExternalLink>
            )}
            {item.image ? (
              item.description === null
            ) : (
              <div
                className={`${item.image ? 'pt-3 pl-3 pr-3' : ''} content text-center`}
              >
                <div className='description'>
                  {HTMLReactParser(item.description)}
                </div>
              </div>
            )}
          </div>
        </DialogContent>
        <Button variant='contained' color='primary' onClick={handleClose}>
          <CloseRounded />
        </Button>
      </BootstrapDialog>
    </div>
  );
};

export default MillsberryClzModal;
