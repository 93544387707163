import React from 'react';

// image
import Image from 'images/bg-img06.jpg';
import Image2 from 'images/curly-wave7.svg';

// react jss
import injectSheet from 'react-jss';

// style
const style = (theme) => ({
  millsberryClzDetailPageBg: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    backgroundPosition: 'top center',
    backgroundColor: 'transparent !important',
    zIndex: 2,
  },
  '@media(max-width: 600px)': {
    millsberryClzDetailPageBg: {
      clipPath: 'unset',
      height: '30vh',
      marginTop: '80px',
    },
  },
  '@media(min-width: 768px)': {
    millsberryClzDetailPageBg: {
      clipPath: 'unset',
      height: '40vh',
      marginTop: '80px',
    },
  },
  '@media(min-width: 992px)': {
    millsberryClzDetailPageBg: {
      clipPath: 'ellipse(69% 100% at 50% 0%)',
      height: '70vh',
      marginTop: '0',
    },
  },
});

const MillsberryClzDetailPage = ({ classes, image }) => {
  return (
    <section
      className={`background-image cover ${classes.millsberryClzDetailPageBg}`}
      style={{ backgroundImage: `url('${image}')` }}
    ></section>
  );
};

export default injectSheet(style)(MillsberryClzDetailPage);
