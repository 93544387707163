import React from 'react';

//new component
const Footer1 = React.lazy(() => import('./footer/1/index'));
const Footer2 = React.lazy(() => import('./footer/2/index'));

const niranjan = {
  Footer1,
  Footer2,
};

export default niranjan;
