import React from 'react';
import HTMLReactParser from 'html-react-parser';
import { styled } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Button } from '@material-ui/core';
import CloseRounded from '@material-ui/icons/CloseRounded';
import injectSheet from 'react-jss';
import CareerVacancies from '../1';

// style
const style = (theme) => ({
  careerVacanciesBg: {
    padding: '100px 0',
  },
  '@media(min-width: 992px)': {
    careerVacanciesBg: {
      '& .container-fluid': {
        // maxWidth: "100%",
      },
    },
  },
  careerVacanciesMain: {
    '& .content-holder': {
      '& .text-holder': {
        textAlign: 'center',
        '& .title': {
          color: '#434343',
          fontSize: '32px',
          fontWeight: 700,
          letterSpacing: '2px',
          display: 'inline-block',
          position: 'relative',
          '& .fa': {
            color: theme.colors.secondary,
            fontSize: '42px',
          },
          '& .bullHorn': {
            transform: 'rotate(180deg) rotateX(180deg)',
          },
        },
      },
      '& .career-holder': {
        '& .career-list': {
          '& .about-school': {
            marginBottom: '30px',
            boxShadow: '0 10px 50px rgb(166 209 237 / 20%)',
            padding: '0 20px',
            borderRadius: '15px',
            '& .logo-holder': {
              textAlign: 'center',
              marginBottom: '20px',
              '& img': {
                height: '80px',
              },
            },
            '& .job-title': {
              fontSize: '24px',
              color: '#434343',
              marginBottom: '100px',
              fontWeight: 700,
              letterSpacing: 1,
            },
            '& .school-image': {
              '& .bg-stretch': {
                height: '550px',
                width: '100%',
              },
            },
            '& .content': {
              '& .school-name': {
                fontSize: '24px',
                letterSpacing: '1px',
                fontWeight: 700,
                color: '#434343',
                position: 'relative',
                display: 'inline-block',
                padding: '10px 0',
                marginBottom: '30px',
                '&::before': {
                  content: "''",
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  height: '2px',
                  width: '50%',
                  background: theme.colors.primary,
                },
                '&::after': {
                  content: "''",
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  height: '2px',
                  width: '50%',
                  background: theme.colors.primary,
                },
              },
              '& .school-description': {
                fontSize: '12px',
                color: '#73738c',
                margin: 0,
                lineHeight: 2.5,
              },
            },
          },
          '& .career-description': {
            marginBottom: '30px',
            boxShadow: '0 10px 50px rgb(166 209 237 / 20%)',
            '& .school-image': {
              width: '100%',
              '& .bg-stretch': {
                height: '100%',
                width: '100%',
              },
            },
            '& .content': {
              '& .school-name': {
                fontSize: '24px',
                letterSpacing: '1px',
                fontWeight: 700,
                color: '#434343',
                position: 'relative',
                display: 'inline-block',
                padding: '10px 0',
                marginBottom: '30px',
                '&::before': {
                  content: "''",
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  height: '2px',
                  width: '50%',
                  background: theme.colors.primary,
                },
                '&::after': {
                  content: "''",
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  height: '2px',
                  width: '50%',
                  background: theme.colors.primary,
                },
              },
              '& .school-description': {
                fontSize: '12px',
                color: '#73738c',
                '& ul': {
                  margin: 0,
                  padding: 0,
                  '& li': {
                    listStyle: 'none',
                    position: 'relative',
                    paddingLeft: '20px',
                    '&:not(:last-child)': {
                      marginBottom: '5px',
                    },
                    '&::before': {
                      content: "''",
                      position: 'absolute',
                      top: '50%',
                      left: 0,
                      transform: 'translate(0, -50%)',
                      height: '6px',
                      width: '6px',
                      background: '#73738c',
                      borderRadius: '50%',
                    },
                  },
                },
                '& p': {
                  margin: '0',
                },
              },
            },
          },
          '& .career-specification': {
            marginBottom: '30px',
            boxShadow: '0 10px 50px rgb(166 209 237 / 20%)',
            '& .marginBottom': {
              '& .school-image': {
                width: '100%',
                '& .bg-stretch': {
                  height: '100%',
                  width: '100%',
                },
              },
              '& .content': {
                '& .school-name': {
                  fontSize: '24px',
                  letterSpacing: '1px',
                  fontWeight: 700,
                  color: '#434343',
                  position: 'relative',
                  display: 'inline-block',
                  padding: '10px 0',
                  marginBottom: '20px',
                  textTransform: 'capitalize',
                  '&::before': {
                    content: "''",
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: '2px',
                    width: '50%',
                    background: theme.colors.primary,
                  },
                  '&::after': {
                    content: "''",
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    height: '2px',
                    width: '50%',
                    background: theme.colors.primary,
                  },
                },
                '& .school-description': {
                  fontSize: '12px',
                  color: '#73738c',
                  '& ul': {
                    margin: 0,
                    padding: 0,
                    '& li': {
                      listStyle: 'none',
                      position: 'relative',
                      paddingLeft: '20px',
                      textTransform: 'capitalize',
                      '&:not(:last-child)': {
                        marginBottom: '5px',
                      },
                      '&::before': {
                        content: "''",
                        position: 'absolute',
                        top: '50%',
                        left: 0,
                        transform: 'translate(0, -50%)',
                        height: '6px',
                        width: '6px',
                        background: '#73738c',
                        borderRadius: '50%',
                      },
                    },
                  },
                  '& p': {
                    margin: '0',
                  },
                },
              },
            },
          },
          '& .terms-condition': {
            boxShadow: '0 10px 50px rgb(166 209 237 / 20%)',
            '& .school-image': {
              width: '100%',
              '& .bg-stretch': {
                height: '100%',
                width: '100%',
              },
            },
            '& .content': {
              '& .school-name': {
                fontSize: '24px',
                letterSpacing: '1px',
                fontWeight: 700,
                color: '#434343',
                position: 'relative',
                display: 'inline-block',
                padding: '10px 0',
                marginBottom: '30px',
                '&::before': {
                  content: "''",
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  height: '2px',
                  width: '50%',
                  background: theme.colors.primary,
                },
                '&::after': {
                  content: "''",
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  height: '2px',
                  width: '50%',
                  background: theme.colors.primary,
                },
              },
              '& .school-description': {
                fontSize: '12px',
                color: '#73738c',
                margin: 0,
                lineHeight: 2,
              },
            },
          },
        },
      },
    },
    '@media(max-width: 600px)': {
      '& .content-holder': {
        '& .career-holder': {
          '& .career-list': {
            '& .about-school': {
              '& .content': {
                padding: '20px 0',
              },
            },
            '& .career-description': {
              '& .school-image': {
                height: '500px',
              },
              '& .content': {
                padding: '20px',
              },
            },
            '& .career-specification': {
              '& .school-image': {
                height: '500px',
              },
              '& .content': {
                padding: '20px',
              },
            },
            '& .terms-condition': {
              padding: '20px 0',
              '& .school-image': {
                height: '500px',
              },
              '& .content': {
                padding: '20px',
              },
            },
          },
        },
      },
    },
    '@media(min-width: 768px)': {
      '& .content-holder': {
        '& .career-holder': {
          '& .career-list': {
            '& .about-school': {
              '& .content': {
                padding: '20px 0',
              },
            },
            '& .career-description': {
              '& .school-image': {
                height: '500px',
              },
              '& .content': {
                padding: '20px',
              },
            },
            '& .career-specification': {
              '& .school-image': {
                height: '500px',
              },
              '& .content': {
                padding: '20px',
              },
            },
            '& .terms-condition': {
              padding: '20px 0',
              '& .school-image': {
                height: '500px',
              },
              '& .content': {
                padding: '20px',
              },
            },
          },
        },
      },
    },
    '@media(min-width: 992px)': {
      '& .content-holder': {
        '& .career-holder': {
          '& .career-list': {
            '& .about-school': {
              '& .content': {
                padding: '20px 0',
              },
            },
            '& .career-description': {
              padding: '0  0 0 20px',
              '& .school-image': {
                height: '100%',
              },
              '& .content': {
                padding: '20px 0',
              },
            },
            '& .career-specification': {
              padding: '0 20px 0 0',
              '& .school-image': {
                height: '100%',
              },
              '& .content': {
                padding: '20px 0',
              },
            },
            '& .terms-condition': {
              padding: '0 20px 20px 0',
              '& .school-image': {
                height: '100%',
              },
              '& .content': {
                padding: '20px',
              },
            },
          },
        },
      },
    },
  },
  outlineButton: {
    borderRadius: '30px !important',
    backgroundColor: theme.colors.primary,
    border: `2px solid ${theme.colors.primary} !important`,
    color: `${theme.colors.primary} !important`,
    transition: 'all .3s ease-out',
    boxShadow: `0 10px 50px ${theme.colors.primary}40`,
    fontFamily: 'unset !important',
    minWidth: '120px !important',
    '&:hover': {
      backgroundColor: `${theme.colors.secondary} !important`,
      boxShadow: `0 10px 50px ${theme.colors.secondary}40`,
      border: `2px solid ${theme.colors.secondary} !important`,
      color: `#fff !important`,
    },
  },
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: '60px 20px 20px 20px',
    borderTop: 'none',
    borderBottom: 'none',
    border: 'none',
    '& .border-content': {
      padding: '20px',
      border: `2px solid ${theme.palette.primary.main}`,
    },
  },
  '& .MuiPaper-root': {
    margin: '20px 0 ',
    boxShadow: 'none',
    borderRadius: '15px',
    overflowY: 'unset',
  },
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
  '& .MuiTypography-root': {
    padding: 0,
  },
  '& .MuiButtonBase-root': {
    color: '#fff',
    background: theme.palette.primary.main,
    boxShadow: 'none',
    height: '30px',
    width: '30px',
    borderRadius: '50%',
    minWidth: 'unset',
    position: 'absolute',
    right: '0px',
    top: '0',
    opacity: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiSvgIcon-root': {
      fontSize: '18px',
    },
    '&:hover': {
      background: theme.palette.secondary.main,
      color: '#fff',
      boxShadow: 'none',
    },
  },
  '@media(max-width: 600px)': {
    '& .MuiDialogContent-root': {
      minWidth: '100%',
    },
    '& .MuiPaper-root': {
      maxWidth: '100%',
    },
  },
  '@media(min-width: 992px)': {
    '& .MuiDialogContent-root': {
      minWidth: '700px',
    },
    '& .MuiPaper-root': {
      maxWidth: '700px',
    },
  },
}));

const Image = 'https://millsberry.edu.np/uploads/career-image/color-logo.png';
const Image2 = 'https://millsberry.edu.np/uploads/career-image/Page2.jpg';
const Image3 =
  'https://millsberry.edu.np/uploads/career-image/Image-for-web-career.jpg';
const Image4 = 'https://millsberry.edu.np/uploads/career-image/Page5.jpg';
const Image5 = 'https://millsberry.edu.np/uploads/career-image/Page6.jpg';

const CareerVacancies4 = ({ classes, data }) => {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('body');
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  return (
    <>
      {data.length ? (
        <section className={`${classes.careerVacanciesBg}`}>
          <div className={`container-fluid ${classes.careerVacanciesMain}`}>
            <div className='content-holder'>
              <div className='career-holder'>
                {data.map((item, i) => {
                  return (
                    <div className='career-list' key={i}>
                      <div className='about-school'>
                        <div className='logo-holder'>
                          <img
                            src={Image}
                            alt='img'
                            className='img img-fluid'
                          />
                        </div>
                        <h3 className='job-title text-center'>
                          {item.job_title}
                        </h3>
                        <div className='row'>
                          <div className='col-lg-6 col-md-12 col-sm-12'>
                            <div className='school-image'>
                              <div
                                className='bg-stretch background-image cover'
                                style={{ backgroundImage: `url('${Image2}')` }}
                              />
                            </div>
                          </div>
                          <div className='col-lg-6 col-md-12 col-sm-12'>
                            <div className='content'>
                              <h3 className='school-name'>Millsberry School</h3>
                              <p className='school-description'>
                                The Millsberry School is a successful family of
                                leading co-educational School providing an
                                outstanding educational experience for students
                                aged 6-18. Millsberry, the Secondary School, is
                                one of the Kathmandu’s leading co-educational
                                day schools; Millsberry international
                                Pre-schools, are a day pre-school for pupils
                                aged 2 to 6, which are situated in five
                                different locations of Kathmandu valley,
                                established in 2004.
                              </p>
                              <p className='school-description'>
                                At MBS all aspects of our educational processes
                                are carefully designed to cultivate and
                                stimulate the intellectual, social, and personal
                                development of the most important stakeholders
                                at this institution; the students.
                              </p>
                              <p className='school-description'>
                                Our students learn the International level
                                curriculum in corporate with Nepal Govt.
                                curriculum, in separate streams. As we are here
                                to serve a global community, we assist
                                internationally-mobile families in making a
                                smooth transfer to any school system or graduate
                                to universities world-wide.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='career-description'>
                        <div className='row'>
                          <div className='col-lg-8 col-md-12 col-sm-12'>
                            <div className='content'>
                              <h3 className='school-name'>Role Description</h3>
                              <div className='school-description'>
                                {HTMLReactParser(item.job_description)}
                              </div>
                            </div>
                          </div>
                          <div className='col-lg-4 col-md-12 col-sm-12'>
                            <div className='school-image'>
                              <div
                                className='bg-stretch background-image cover'
                                style={{ backgroundImage: `url('${Image3}')` }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {item.personal_specification ? (
                        <div className='career-specification'>
                          <div className='row'>
                            <div className='col-lg-4 col-md-12 col-sm-12 marginBottom'>
                              <div className='school-image'>
                                <div
                                  className='bg-stretch background-image cover'
                                  style={{
                                    backgroundImage: `url('${Image4}')`,
                                  }}
                                />
                              </div>
                            </div>
                            <div className='col-lg-8 col-md-12 col-sm-12 marginBottom'>
                              <div className='content'>
                                <h3 className='school-name'>
                                  Personal Specification
                                </h3>
                                <div className='school-description'>
                                  {HTMLReactParser(item.personal_specification)}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {item.application_process || item.term ? (
                        <div className='terms-condition'>
                          <div className='row mb-5'>
                            <div className='col-lg-4 col-md-12 col-sm-12'>
                              <div className='content'>
                                <h3 className='school-name'>
                                  Employment Terms And Conditions
                                </h3>
                                <div className='school-description'>
                                  {HTMLReactParser(item.terms)}
                                </div>
                              </div>
                            </div>
                            <div className='col-lg-4 col-md-12 col-sm-12'>
                              <div className='school-image'>
                                <div
                                  className='bg-stretch background-image cover'
                                  style={{
                                    backgroundImage: `url('${Image5}')`,
                                  }}
                                />
                              </div>
                            </div>
                            <div className='col-lg-4 col-md-12 col-sm-12'>
                              <div className='content'>
                                <h3 className='school-name'>
                                  Application Process
                                </h3>
                                <div className='school-description'>
                                  {HTMLReactParser(item.application_process)}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='career-modal text-right'>
                            <Button
                              variant='outlined'
                              color='primary'
                              onClick={handleClickOpen}
                              className={`${classes.outlineButton}`}
                            >
                              Apply Now
                            </Button>
                            <BootstrapDialog
                              onClose={handleClose}
                              aria-labelledby='customized-dialog-title'
                              open={open}
                              scroll={scroll}
                            >
                              <DialogContent dividers={scroll === 'body'}>
                                <CareerVacancies career_id={item.id} />
                              </DialogContent>
                              <Button
                                variant='contained'
                                color='primary'
                                onClick={handleClose}
                              >
                                <CloseRounded />
                              </Button>
                            </BootstrapDialog>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
      ) : null}
    </>
  );
};

export default injectSheet(style)(CareerVacancies4);
