import React from 'react';

// react parser
import parse from 'html-react-parser';

// react accordions
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// react jss
import injectSheet from 'react-jss';
import AitmBannerImage from '../../../components/aitm-banner-image';

// style
const style = (theme) => ({
  root: {
    width: '100%',
    '& .MuiAccordion-root': {
      boxShadow: 'none',
    },
    '& .MuiAccordion-rounded:first-child': {
      borderTopLeftRadius: '15px',
      borderTopRightRadius: '15px',
    },
    '& .MuiAccordion-rounded:last-child': {
      borderBottomLeftRadius: '15px',
      borderBottomRightRadius: '15px',
    },
    '& .MuiAccordion-root.Mui-expanded:first-child': {
      borderBottomLeftRadius: '15px',
      borderBottomRightRadius: '15px',
    },
    '& .MuiAccordion-root.Mui-expanded:last-child': {
      borderTopLeftRadius: '15px',
      borderTopRightRadius: '15px',
    },
    '& .MuiAccordion-root.Mui-expanded': {
      borderRadius: '15px',
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      background: `${theme.colors.primary}`,
      borderRadius: '15px 15px 0px 0px',
      color: '#fff',
      '& .MuiSvgIcon-root': {
        fill: '#fff',
      },
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      color: '#fff',
    },
    '& .MuiAccordionDetails-root': {
      padding: '20px 16px',
      '& p': {
        fontSize: '16px',
        color: '#73738c',
        margin: '0',
        padding: '0',
      },
    },
    '& .MuiAccordionSummary-content': {
      margin: '20px 0',
      color: '#434343',
      fontSize: '16px',
      fontWeight: 500,
    },
  },
  faqBg: {
    padding: '100px 0',
    background: 'rgb(64 105 181 / 12%)',
    marginBottom: '100px',
  },
  faqMain: {
    '& .content-holder': {},
  },
});

const Faq = ({ classes, faq, theme }) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panelIndex) => (event, isExpanded) => {
    setExpanded(isExpanded ? 'panel' + panelIndex : false);
  };
  const isBannerImage =
    theme.domain === 'aitm.edu.np' ||
    theme.domain === 'elixir.edu.np' ||
    theme.domain === 'nobel.edu.np' ||
    theme.domain === 'lna.edu.np' ||
    theme.domain === 'spa.edu.np' ||
    theme.domain === 'grs.edu.np';
  return (
    <>
      {isBannerImage ? (
        <AitmBannerImage
          image={theme.resourcePath + 'banner/' + theme.banner}
          theme={theme}
        />
      ) : null}
      <>
        {faq.data.length ? (
          <section className={`${classes.faqBg}`}>
            <div className={`container-fluid ${classes.faqMain}`}>
              <div className='content-holder'>
                <div className={classes.root}>
                  {faq.data.map((item, i) => {
                    return (
                      <Accordion
                        expanded={expanded === 'panel' + i}
                        onChange={handleChange(i)}
                        key={i++}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls='panel1bh-content'
                          id='panel1bh-header'
                        >
                          {item.title}
                        </AccordionSummary>
                        <AccordionDetails>
                          {parse(item.description)}
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                </div>
              </div>
            </div>
          </section>
        ) : null}
      </>
    </>
  );
};

export default injectSheet(style)(Faq);
