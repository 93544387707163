import * as types from './actionTypes';
import Api from '../utils/Api';

//pushing routes for redirecting to pages
import { push } from 'react-router-redux';

//method that accepts theme data and generates theme object
import generateTheme from '../utils/theme';

//pace page loader
import NProgress from 'nprogress';
import '../../node_modules/nprogress/nprogress.css';

NProgress.configure({ easing: 'ease', speed: 500 });

const api = new Api();

// Website
export const WebsiteData = (data) => {
  return { type: types.FETCH_WEBSITE_DATA, payload: data };
};
// Content
export const ContentData = (data) => {
  return { type: types.FETCH_CONTENT_DATA, payload: data };
};

function getKeysWithoutData(websiteData) {
  let keyString = '';
  Object.entries(websiteData).map(([key, item], i) => {
    if (
      key !== 'page' &&
      !key.startsWith('single') &&
      (!item.data.length || key === 'gallery')
    )
      keyString += (keyString ? ',' : '') + key;
  });
  return keyString;
}

// Layout Data
export const LayoutData = (data) => {
  return { type: types.FETCH_LAYOUT_DATA, payload: data };
};

export function getLayoutData() {
  const path = '/layout';
  return function (dispatch, getState) {
    return api
      .get(path, getKeysWithoutData(getState().websiteData))
      .then((response) => {
        let layout = response.body.layout;
        layout.isLoaded = true;
        layout.theme = generateTheme(layout.theme, layout.profile);

        if (response.success) {
          dispatch(WebsiteData(response.body.data));
        } else {
          layout.underMaintenance = true;
        }
        dispatch(LayoutData(layout));
      })
      .catch((err) => dispatch(push('/502')));
  };
}

//set layout data(for setting data provided through window object. Helpful to reduce api call for layout data and so page loading time. Left for future research)
export function setLayoutData(layout) {
  return function (dispatch) {
    dispatch(LayoutData(layout));
  };
}

// Home Page Data
export const HomePageData = (data) => {
  return { type: types.FETCH_HOME_DATA, payload: data };
};

export function getHomePageData() {
  const path = '/home';
  return function (dispatch, getState) {
    return api
      .get(path, getKeysWithoutData(getState().websiteData))
      .then((response) => {
        if (response.success) {
          const homePageData = {
            isLoaded: true,
            blocks: response.body.blocks,
          };
          dispatch(HomePageData(homePageData));
          dispatch(WebsiteData(response.body.data));
        }
      })
      .catch((err) => dispatch(push('/502')));
  };
}

export const PageData = (data) => {
  return { type: types.FETCH_PAGE_DATA, payload: data };
};
export const PhotoGalleryPageData = (data) => {
  return { type: types.FETCH_PHOTOGALLERY_DATA, payload: data };
};
export const PhotoListPageData = (data) => {
  return { type: types.FETCH_PHOTOLIST_DATA, payload: data };
};
export const PblPhotoGalleryPageData = (data) => {
  return { type: types.FETCH_PBL_PHOTOGALLERY_DATA, payload: data };
};
export const PblPhotoListPageData = (data) => {
  return { type: types.FETCH_PBL_PHOTOLIST_DATA, payload: data };
};
export const VideoGalleryPageData = (data) => {
  return { type: types.FETCH_VIDEOGALLERY_DATA, payload: data };
};
export const ContactPageData = (data) => {
  return { type: types.FETCH_CONTACT_DATA, payload: data };
};

function getFirstPageData(menu, slug) {
  return new Promise((resolve) => {
    menu.forEach(function (obj) {
      if (obj.slug === slug) {
        if (obj.submenu && obj.submenu.length) {
          resolve(obj.slug + '/' + obj.submenu[0].slug);
        }
      } else {
        if (obj.submenu && obj.submenu.length) {
          obj.submenu.forEach(function (objSub) {
            if (objSub.slug === slug) {
              if (objSub.menuLine && objSub.menuLine.length) {
                resolve(
                  obj.slug + '/' + objSub.slug + '/' + objSub.menuLine[0].slug
                );
              }
            }
          });
        }
      }
    });
  });
}

export function getPageData(url) {
  NProgress.start();
  return function (dispatch, getState) {
    return api
      .get(url, getKeysWithoutData(getState().websiteData))
      .then((response) => {
        if (response.success) {
          // redirect to first child page when page has no data
          const responseData = response.body.data;
          if (responseData.page && !responseData.page.data) {
            const menu = getState().layout.menu;

            getFirstPageData(menu, responseData.page.base_slug).then((slug) => {
              dispatch(push('/' + slug));
            });
          } else {
            const data = {
              isLoaded: true,
              blocks: response.body.blocks,
            };

            let content = {
              key: response.body.key,
              url: url,
              data: null,
            };

            switch (content.key) {
              case 'gallery':
                content.page = 'photoGalleryPage';
                dispatch(PhotoGalleryPageData(data));
                break;

              case 'singleGallery':
                content.page = 'singleGalleryPage';
                dispatch(PhotoListPageData(data));
                break;

              case 'pblgallery':
                content.page = 'pblPhotoGalleryPage';
                dispatch(PblPhotoGalleryPageData(data));
                break;

              case 'singlePblgallery':
                content.page = 'pblSingleGalleryPage';
                dispatch(PblPhotoListPageData(data));
                break;

              case 'video':
                content.page = 'videoGalleryPage';
                dispatch(VideoGalleryPageData(data));
                break;

              case 'contact':
                content.page = 'contactPage';
                dispatch(ContactPageData(data));
                break;

              default:
                content.page = 'page';
                dispatch(PageData(data));
            }

            if (!content.key || content.key.startsWith('single')) {
              content.data = responseData;
            }

            dispatch(ContentData(content));
            dispatch(WebsiteData(responseData));
          }
        } else {
          dispatch(push('/404'));
        }
        NProgress.done();
      })
      .catch((error) => dispatch(push('/502')));
  };
}
export function setWebsiteData(data) {
  return function (dispatch) {
    dispatch(WebsiteData(data));
  };
}

//send contact message
export function sendContactMessage(formData) {
  return () => {
    return new Promise((resolve) => {
      resolve(api.create('/messageus/submit', formData));
    });
  };
}

// Feedback Message
export function feedBackMessage(formData) {
  return () => {
    return new Promise((resolve) => {
      resolve(api.create('/send-inquiry', formData));
    });
  };
}

//send feedback message
export function subscribeMessage(formData) {
  return () => {
    return new Promise((resolve) => {
      resolve(api.create('/subscribe', formData));
    });
  };
}

//Archive Submission Message
export function SubmissionForm(formData) {
  return () => {
    return new Promise((resolve) => {
      resolve(api.create('/archives', formData));
    });
  };
}

// error handling
export const ErrorData = (data) => {
  return { type: types.SET_ERROR, payload: data };
};
