import React from 'react';

// import { ThreeDots } from 'svg-loaders-react';
// <ThreeDots fill="#ccc" width={35} height={35} />;

import { PointSpreadLoading } from 'react-loadingg';

//theming
import injectSheet from 'react-jss';

// asset import
// import "./Loader.css";
// import RenderTemplate from "../../containers/RenderTemplate";

import Loader1 from './4/index';
// const Loader2 = React.lazy(() => import('./2'));
// const Loader3 = React.lazy(() => import('./3'));
// const Loader4 = React.lazy(() => import('./4'));
// const Loader5 = React.lazy(() => import('./5'));
// const Loader6 = React.lazy(() => import('./6'));
// const Loader7 = React.lazy(() => import('./6'));
// const Loader8 = React.lazy(() => import('./8'));
// const Loader9 = React.lazy(() => import('./9'));
// const Loader10 = React.lazy(() => import('./12'));
// const Loader11 = React.lazy(() => import('./11'));
// const Loader12 = React.lazy(() => import('./12'));
// const Loader13 = React.lazy(() => import('./middle-header'));
// const Loader14 = React.lazy(() => import('./14'));
// const Loader15 = React.lazy(() => import('./15'));
import Loader16 from './16';
import Loader17 from './17';
import DavNepalLoader from './19';
import Loader21 from './21';
import Loader23 from './23';

// const templateList = { Loader1, Loader2, Loader3, Loader4, Loader5, Loader6, Loader7, Loader8, Loader9, Loader10, Loader11, Loader12, Loader13, Loader14, Loader15 };
// let template = { id: 4 };

const styles = (theme) => ({});

const Loader = ({ type, classes }) => {
  const hostname =
    (window.location.hostname.indexOf('www.') && window.location.hostname) ||
    window.location.hostname.replace('www.', '');
  const stXavier = 'sxc.edu.np';
  const Loyola = 'loyola.sxc.edu.np';
  const bktNist = 'bktnist.edu.np';
  const davNepal = 'davnepal.edigitalnepal.edu.np';
  const millsberrySchool = 'millsberry.com.np';
  const millsberryClz = 'millsberry.edu.np';
  const davNepal2 = 'website.davnepal.edu.np';

  const loader = (hostname) => {
    let tempLoader;
    switch (hostname) {
      case stXavier:
      case Loyola:
        tempLoader = <Loader16 />;
        break;

      case bktNist:
        tempLoader = <Loader17 />;
        break;

      case davNepal:
      case davNepal2:
        tempLoader = <DavNepalLoader />;
        break;

      case millsberryClz:
        tempLoader = <Loader21 />;
        break;

      case millsberrySchool:
        tempLoader = <Loader23 />;
        break;

      default:
        tempLoader = <Loader1 />;
        break;
    }

    return tempLoader;
  };

  return (
    <div className={`loader-overlay ${classes.loaderMain}`}>
      <div className='loader-container'>
        {type === 'site-loader' ? (
          loader(hostname)
        ) : (
          <PointSpreadLoading color={'#ccc'} />
        )}
      </div>
    </div>
  );
};

export default injectSheet(styles)(Loader);
