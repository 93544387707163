import React from 'react';
import { Link } from 'react-router-dom';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';

import injectSheet from 'react-jss';

// style
const style = (theme) => ({
  careerVacanciesBg: {
    padding: '100px 0 70px 0',
  },
  careerVacanciesMain: {
    '& .content-holder': {
      '& .text-holder': {
        textAlign: 'center',
        '& .title': {
          color: '#434343',
          fontSize: '32px',
          fontWeight: 700,
          letterSpacing: '2px',
          display: 'inline-block',
          position: 'relative',
          '& .fa': {
            color: theme.colors.secondary,
            fontSize: '42px',
          },
          '& .bullHorn': {
            transform: 'rotate(180deg) rotateX(180deg)',
          },
        },
      },
      '& .marginBottom': {
        marginBottom: '30px',
        '& .career-list': {
          boxShadow: '0 10px 50px rgb(166 209 237 / 20%)',
          padding: '20px',
          borderRadius: '15px',
          '& .career-title': {
            marginBottom: '10px',
            '& a': {
              textDecoration: 'none',
              fontSize: '22px',
              color: '#434343',
              fontWeight: 500,
              transition: 'all .3s ease-out',
              '&:hover': {
                color: theme.colors.secondary,
              },
            },
          },
          '& .content': {
            '& ul': {
              margin: 0,
              padding: 0,
              marginBottom: '10px',
              '& li': {
                listStyle: 'none',
                display: 'inline-block',
                color: '#73738c',
                fontSize: '16px',
                '&:not(:last-child)': {
                  marginRight: '15px',
                },
                '& span': {
                  fontWeight: 500,
                },
              },
            },
            '& .read-more': {
              '& a': {
                textDecoration: 'none',
                color: theme.colors.primary,
                transition: 'all .3s ease-out',
                '& .MuiSvgIcon-root': {
                  fontSize: '18px',
                },
                '&:hover': {
                  color: theme.colors.secondary,
                },
              },
            },
          },
        },
      },
    },
  },
});

const CareerVacancies3 = ({ classes, data }) => {
  return (
    <>
      {data.length ? (
        <section className={`${classes.careerVacanciesBg}`}>
          <div className={`container-fluid ${classes.careerVacanciesMain}`}>
            <div className='content-holder'>
              <div className='text-holder mb-5'>
                <h2 className='title'>
                  <i className='fa fa-bullhorn mr-3' />
                  Vacancy Announcement
                  <i className='fa fa-bullhorn ml-3 bullHorn' />
                </h2>
              </div>
              <div className={classes.root}>
                <div className='row'>
                  {data.map((item, i) => {
                    return (
                      <div
                        className='col-lg-6 col-md-12 col-sm-12 mb-4 marginBottom'
                        key={i}
                      >
                        <div className='career-list'>
                          <div className='career-title'>
                            <Link to={`career/${item.id}`}>
                              {item.job_title}
                            </Link>
                          </div>
                          <div className='content'>
                            <ul>
                              <li>
                                <span>Type: </span>
                                {item.employment_type} Time
                              </li>
                              <li>
                                <span>Min.Experience: </span>
                                {item.experience_required} Years
                              </li>
                              <li>
                                <span>No.of Vacancy: </span>
                                {item.no_of_vacancy}
                              </li>
                            </ul>
                            <div className='read-more'>
                              <Link to={`career/${item.id}`}>
                                Read More
                                <ArrowForwardRoundedIcon />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : null}
    </>
  );
};

export default injectSheet(style)(CareerVacancies3);
