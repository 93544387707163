import React from 'react';
import { Link } from 'react-router-dom';

const PageTitle = ({ title, link }) => {
  let pageTitle = <h1 className={`h2 mb-4`}>{title}</h1>;
  if (link) pageTitle = <Link to={link}>{pageTitle}</Link>;
  return pageTitle;
};

export default PageTitle;
