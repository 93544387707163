import React, { Component } from 'react';
import './index.css';
import injectSheet from 'react-jss';
import { ToastContainer } from 'react-toastify';
import toast from './../../../components/notification/Notify';
import TextField from '@material-ui/core/TextField';
import Loader from 'react-loader-spinner';
import { connect } from 'react-redux';
import axios from 'axios';

// theme
const style = (theme) => ({
  contactBg: {
    position: 'relative',
  },
  contactSec: {
    padding: '0px',
    '& .submission-form': {
      '& .MuiTextField-root': {
        width: '100%',
      },
      '& .MuiInputBase-root': {
        fontFamily: 'unset',
      },
      '& .field-list': {
        marginBottom: '20px',
        '& .divider': {
          '&:not(:last-child)': {
            marginRight: '20px',
          },
          '& label': {
            color: theme.colors.primary,
            fontWeight: 500,
          },
        },
        '& .error-message': {
          color: '#dc3545',
          margin: '0',
          marginTop: '5px',
          padding: '0',
        },
      },
      '& .btn-outline-primary': {
        background: theme.colors.primary,
        border: `2px solid ${theme.colors.primary}`,
        color: '#fff',
        fontSize: '16px',
        fontWeight: 500,
        letterSpacing: '2px',
        marginTop: '30px !important',
        minWidth: '150px',
        textTransform: 'capitalize',
        fontFamily: 'unset',
        boxShadow: `inset 0 0 0 0 #fff, rgb(0 33 71 / 30%) 8px 8px 30px 0px`,
        transition: 'all .3s ease-out',
        '&:hover': {
          background: '#fff',
          boxShadow: `inset 150px 0 0 0 ${theme.colors.secondary} ,rgb(247 148 30 / 30%) 8px 8px 30px 0px`,
          border: `2px solid ${theme.colors.secondary}`,
        },
      },
    },
    '& .form-control': {
      backgroundColor: 'transparent',
    },
    '& .text-holder': {
      position: 'absolute',
      top: 0,
      left: '0px',
      height: '200px',
      width: '100%',
      background: theme.colors.primary,
      borderRadius: '15px 15px 50% 50%',
      display: 'grid',
      placeItems: 'center',
      '& .title': {
        fontSize: '32px',
        fontWeight: 700,
        color: '#fff',
        paddingBottom: '10px',
        letterSpacing: '2px',
        position: 'relative',
        '&::before': {
          content: "''",
          position: 'absolute',
          bottom: 0,
          left: '50%',
          transform: 'translate(-50%, 0)',
          background: '#fff',
          height: '3px',
          width: '80px',
        },
      },
    },
  },
});

const DefaultForm = {
  name: '',
  email: '',
  phone: '',
  message: '',
  file: [],
  career_id: '',
  profile_image: [],
  cover_letter: [],
};

class CareerVacancies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        ...DefaultForm,
      },
      error: {
        ...DefaultForm,
      },
      validForm: false,
      isSubmitting: false,
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState(
      (previousState) => ({
        data: {
          ...previousState.data,
          [name]:
            name === 'file' ||
            name === 'profile_image' ||
            name === 'cover_letter'
              ? e.target.files
              : value,
        },
      }),
      () => {
        this.validateErrors(name);
      }
    );
  };

  validateErrors(fieldName) {
    let error;
    switch (fieldName) {
      case 'name':
        error = this.state.data[fieldName].trim().length
          ? ''
          : 'Name is Required';
        break;
      case 'email':
        if (this.state.data[fieldName].trim().length) {
          error = this.state.data.email.includes('@') ? '' : 'Email is Invalid';
        } else {
          error = 'Email is Required';
        }
        break;
      case 'phone':
        error = this.state.data[fieldName].trim().length
          ? ''
          : 'Phone is Required';
        break;
      case 'message':
        error = this.state.data[fieldName].trim().length
          ? ''
          : 'Message is Required';
        break;
      case 'file':
        error = this.state.data[fieldName].length ? '' : 'File is Required';
        break;
      case 'career_id':
        error = '';
        break;
      case 'profile_image':
        error = this.state.data[fieldName].length
          ? ''
          : 'Profile Image is Required';
        break;
      case 'cover_letter':
        error = this.state.data[fieldName].length
          ? ''
          : 'Cover Letter is Required';
        break;
      default:
        break;
    }
    this.setState((previousState) => ({
      error: {
        ...previousState.error,
        [fieldName]: error,
      },
    }));
    return error?.length === 0;
  }

  validateForm() {
    let isValid = true;
    // eslint-disable-next-line array-callback-return
    Object.keys(this.state.data).map((key) => {
      let isValidStatus = this.validateErrors(key);
      if (isValid) {
        isValid = isValidStatus;
      }
    });
    return isValid;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (!this.validateForm()) return;

    this.setState({
      isSubmitting: true,
    });

    const formData = new FormData();

    for (const [name, value] of Object.entries(this.state.data)) {
      if (name === 'file') {
        for (let i = 0; i < value.length; i++) {
          formData.append('file[]', value[i]);
        }
      } else if (name === 'profile_image' || name === 'cover_letter') {
        formData.append(name, value[0]);
      } else {
        formData.append(name, value);
      }
    }

    const domain = this.props.theme.domain;
    axios
      .post(`https://${domain}/api/career/apply?client=${domain}`, formData)
      .then((response) => {
        if (response.data.success) {
          toast.showSuccess('Message Sent SuccessFully');
          this.setState({
            data: DefaultForm,
          });
          document.getElementById('formId').reset(); // form reset after submit
        } else {
          toast.showError(
            response.message ? 'Message Sent SuccessFully' : 'Sent Failed'
          );
        }
      })
      .catch((err) => {
        toast.showError('Something went wrong when sending File!');
      })
      .finally(() => {
        this.setState({
          isSubmitting: false,
        });
      });
  };

  componentDidUpdate(prevProps) {
    if (this.props.careerId !== prevProps.careerId) {
      this.setState((prevState) => ({
        data: { ...prevState.data, career_id: this.props.careerId },
      }));
    }
  }

  render() {
    const { classes, career_id } = this.props;
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.data.career_id = career_id; // update career_id in state
    return (
      <section id='contact-sec' className={`${classes.contactBg}`}>
        <div className={`contact-sec container-fluid ${classes.contactSec}`}>
          <div className='submission-form'>
            <form
              noValidate
              onSubmit={this.handleSubmit}
              id='formId'
              encType='multipart/form-data'
            >
              <div className='field-list'>
                <TextField
                  name='name'
                  value={this.state.data.name}
                  onChange={this.handleChange}
                  label='Name'
                  type='text'
                  autoFocus
                />
                <p className='error-message'>{this.state.error.name}</p>
              </div>
              <div className='field-list'>
                <TextField
                  label='Email'
                  name='email'
                  value={this.state.data.email}
                  onChange={this.handleChange}
                  type='email'
                />
                <p className='error-message'>{this.state.error.email}</p>
              </div>
              <div className='field-list'>
                <TextField
                  name='phone'
                  value={this.state.data.phone}
                  onChange={this.handleChange}
                  label='Phone'
                  type='phone'
                />
                <p className='error-message'>{this.state.error.phone}</p>
              </div>
              <div className='field-list mb-4'>
                <TextField
                  name='message'
                  value={this.state.data.message}
                  onChange={this.handleChange}
                  label='Message'
                  type='text'
                />
                <p className='error-message'>{this.state.error.message}</p>
              </div>
              <div className='field-list d-flex align-items-center justify-content-between'>
                <div className='divider'>
                  <label form='file'>Upload CV:</label>
                  <TextField
                    name='file'
                    id='file'
                    // value={this.state.data.file}
                    onChange={this.handleChange}
                    type='file'
                    accept='.pdf,.doc,.docx,.xls,.xlsx,.txt'
                    multiple
                  />
                  <p className='error-message'>{this.state.error.file}</p>
                </div>
                <div className='divider'>
                  <label form='profile_image'>Profile Image:</label>
                  <TextField
                    name='profile_image'
                    id='file1'
                    // value={this.state.data.profile_image}
                    onChange={this.handleChange}
                    type='file'
                  />
                  <p className='error-message'>
                    {this.state.error.profile_image}
                  </p>
                </div>
                <div className='divider'>
                  <label form='cover_letter'>Cover Letter:</label>
                  <TextField
                    name='cover_letter'
                    id='file2'
                    // value={this.state.data.cover_letter}
                    onChange={this.handleChange}
                    type='file'
                  />
                  <p className='error-message'>
                    {this.state.error.cover_letter}
                  </p>
                </div>
              </div>
              {this.state.isSubmitting ? (
                <button
                  className='btn btn-outline-primary rounded-pill'
                  type='submit'
                  disabled={!this.state.validForm}
                  onClick={this.toast}
                >
                  <Loader
                    type='Puff'
                    color='#fff'
                    height={30}
                    width={30}
                    timeout={3000} //3 secs
                  />
                </button>
              ) : (
                <button
                  className='btn btn-outline-primary rounded-pill'
                  type='submit'
                  onClick={this.toast}
                >
                  Submit
                </button>
              )}
            </form>
          </div>
          <ToastContainer />
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    template: state.layout.template.contact,
    map: state.layout.profile.data.map,
    bannerPath: state.layout.profile.resource_path,
    bannerImage: state.layout.profile.data.banner,
    breadCrumb: state.breadCrumb,
    activeBreadCrumb: state.activeBreadCrumb,
    profile: state.layout.profile,
    socialmedia: state.layout.socialmedia,
  };
}

export default connect(mapStateToProps)(injectSheet(style)(CareerVacancies));
