import React from 'react';
import injectSheet from 'react-jss';
import { Link } from 'react-router-dom';
import HTMLReactParser from 'html-react-parser';

// style
const style = (theme) => ({
  qaPageBg: {
    padding: '50px 0 20px 0',
  },
  qaPageMain: {
    '& .content-holder': {
      '& .text-holder': {
        marginBottom: '30px',
        '& .title': {
          color: '#434343',
          fontWeight: 700,
          letterSpacing: '2px',
          fontSize: '32px',
        },
      },
      '& .marginBottom': {
        marginBottom: '30px',
        '& .qa-list': {
          borderRadius: '15px',
          boxShadow: '8px 8px 30px 0px rgb(42 67 113 / 12%)',
          padding: '20px',
          '& .qa-title': {
            marginBottom: '10px',
            '& a': {
              textDecoration: 'none',
              color: '#434343',
              fontSize: '22px',
              fontWeight: 700,
              letterSpacing: '1px',
              transition: 'all .3s ease-out',
              '&:hover': {
                color: theme.colors.primary,
              },
            },
          },
          '& .description': {
            color: '#73738c',
            fontSize: '16px',
            lineHeight: 1.7,
          },
          '& .buttons-holder': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            '& .button': {
              '& .btn-outline-primary': {
                textDecoration: 'none',
                color: '#fff',
                background: theme.colors.secondary,
                boxShadow: `8px 8px 30px 0px ${theme.colors.secondary}52`,
                transition: 'all .3s ease-out',
                fontSize: '16px',
                fontWeight: 500,
                borderRadius: '10px',
                border: 'none',
                '&:hover': {
                  background: theme.colors.primary,
                  boxShadow: `8px 8px 30px 0px ${theme.colors.primary}52`,
                },
              },
            },
            '& .download-button': {
              '& .btn-outline-primary': {
                textDecoration: 'none',
                fontSize: '16px',
                background: theme.colors.primary,
                color: '#fff',
                border: 'none',
                boxShadow: `8px 8px 30px 0px ${theme.colors.primary}52`,
                borderRadius: '10px',
                transition: 'all .3s ease-out',
                '&:hover': {
                  background: theme.colors.secondary,
                  boxShadow: `8px 8px 30px 0px ${theme.colors.secondary}52`,
                },
              },
            },
          },
        },
      },
    },
  },
});

const QAPage = ({ classes, qa }) => {
  return (
    <>
      {qa.data.length ? (
        <section className={`${classes.qaPageBg}`}>
          <div className={`container-fluid ${classes.qaPageMain}`}>
            <div className='content-holder'>
              <div className='text-holder text-center'>
                <h2 className='title'>{qa.title}</h2>
              </div>
              <div className='row'>
                {qa.data.map((item, i) => {
                  let file = item.file || item.image;
                  return (
                    <div
                      className='col-lg-6 col-md-12 col-sm-12 marginBottom'
                      key={item.slug}
                    >
                      <div className='qa-list'>
                        <div className='qa-title'>
                          {item.external_link ? (
                            <a
                              href={item.external_link}
                              target='_blank'
                              rel='noreferrer'
                            >
                              {item.title}
                            </a>
                          ) : (
                            <Link to={qa.base_slug + item.slug}>
                              {item.title}
                            </Link>
                          )}
                        </div>
                        {item.image ? (
                          <img
                            src={qa.resource_path + item.image}
                            alt={item.title}
                            className='img img-fluid mb-3'
                          />
                        ) : null}
                        <div className='description'>
                          {HTMLReactParser(item.description)}
                        </div>
                        <div className='buttons-holder'>
                          <div className='button'>
                            {item.external_link ? (
                              <a
                                href={item.external_link}
                                target='_blank'
                                rel='noreferrer'
                                className='btn btn-outline-primary'
                              >
                                View Answer
                              </a>
                            ) : (
                              <Link
                                to={qa.base_slug + item.slug}
                                className='btn btn-outline-primary'
                              >
                                View Answer
                              </Link>
                            )}
                          </div>
                          {file ? (
                            <div className='download-button'>
                              <a
                                href={qa.resource_path + file}
                                download={file}
                                target='_blank'
                                className='btn btn-outline-primary'
                              >
                                Download
                              </a>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
      ) : null}
    </>
  );
};

export default injectSheet(style)(QAPage);
