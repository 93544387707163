import { Box } from '@material-ui/core';

export default function ViewResult() {
  return (
    <Box
      sx={{
        mt: {
          xs: '80px',
          md: 0,
        },
      }}
    >
      <iframe
        title='View Result'
        src='https://new.sxc.edu.np/view-result?token=DZK0s4A721xQ0epRUp2u'
        frameborder='0'
        style={{
          width: '100%',
          height: '641px',
        }}
      ></iframe>
    </Box>
  );
}
