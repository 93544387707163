import React from 'react';

// css
import './index.css';

// image
import Image from 'images/dav-school-logo.png';

// react jss
import injectSheet from 'react-jss';
import css from '@emotion/css';

// style
const style = (theme) => ({
  davNepalLoaderBg: {
    padding: '50px 0',
  },
  davNepalLoaderMain: {
    '& .content-holder': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '50px',
      animation: 'fadeIn ease-out 3s',
      '& .img-holder': {
        height: '300px',
        width: '300px',
        '& .bg-stretch': {
          height: '100%',
          width: '100%',
        },
      },
    },
    '& .type-effect': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    },
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: '0',
      transform: 'scale(.5)',
    },
    '100%': {
      opacity: '1',
      transform: 'scale(1)',
    },
  },
});

const DavNepalLoader = ({ classes }) => {
  return (
    <section className={`${classes.davNepalLoaderBg}`}>
      <div className={`${classes.davNepalLoaderMain}`}>
        <div className='content-holder'>
          <div className='img-holder'>
            <img src={Image} className='bg-stretch' />
          </div>
        </div>
        <div className='type-effect'>
          <p>Welcome To DAV SUSHIL KEDIA BHARATI</p>
        </div>
      </div>
    </section>
  );
};

export default injectSheet(style)(DavNepalLoader);
