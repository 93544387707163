import React from 'react';

// image
import Image from './../../../images/transparent.png';

// react jss
import injectSheet from 'react-jss';

// style
const style = (theme) => ({
  nistLoaderBg: {
    background: '#FFF',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  nistLoaderMain: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      animation: 'flip 1.2s infinite ease-in-out',
      animationDuration: '1s',
      animationFillMode: 'both',
    },
  },
  '@keyframes flip': {
    '0%': {
      transform: 'perspective(500px) rotateY(0)',
      animationTimingFunction: 'ease-out',
    },
    '40%': {
      transform: 'perspective(500px) translateZ(150px) rotateY(170deg)',
      animationTimingFunction: 'ease-out',
    },
    '50%': {
      transform:
        'perspective(500px) translateZ(150px) rotateY(190deg) scale(1)',
      animationTimingFunction: 'ease-in',
    },
    '80%': {
      transform: 'perspective(500px) rotateY(360deg) scale(.95)',
      animationTimingFunction: 'ease-in',
    },
    '100%': {
      transform: 'perspective(500px) scale(1)',
      animationTimingFunction: 'ease-in',
    },
  },
});

const Loader16 = ({ classes }) => {
  return (
    <section id='nist-loader' className={`${classes.nistLoaderBg}`}>
      <div className={`container-fluid ${classes.nistLoaderMain}`}>
        <div className='content-holder'>
          <img src={Image} className='img-fluid' alt='img' />
          <div className='hi'></div>
        </div>
      </div>
    </section>
  );
};

export default injectSheet(style)(Loader16);
