import React from 'react';
import { Link } from 'react-router-dom';
import injectSheet from 'react-jss';
import Button from '@material-ui/core/Button';

const style = (theme) => ({
  root: {
    padding: '100px 0',
    '& .career': {
      '& .text-holder': {
        '& .title': {
          fontSize: '32px',
          fontWeight: 'bold',
          color: '#434343',
        },
      },
      '& .career-item': {
        boxShadow: '0 10px 50px #ccc',
        borderRadius: '15px',
        padding: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& .content': {
          '& .job-title': {
            fontSize: '22px',
            color: theme.colors.primary,
            fontWeight: 'bold',
          },
          '& .specification': {
            '& ul': {
              margin: 0,
              padding: 0,
              '& li': {
                display: 'inline-block',
                position: 'relative',
                color: '#73738c',
                paddingLeft: '15px',
                '&:not(:last-child)': {
                  marginRight: '15px',
                },
                '&::before': {
                  content: "''",
                  position: 'absolute',
                  left: 0,
                  top: '50%',
                  transform: 'translateY(-50%)',
                  background: '#73738c',
                  height: '6px',
                  width: '6px',
                  borderRadius: '50%',
                },
              },
            },
          },
        },
        '& .MuiButtonBase-root': {
          borderRadius: '8px',
          boxShadow: 'none',
          minWidth: '100px',
          padding: '10px 30px',
          textTransform: 'capitalize',
          fontFamily: 'unset',
          display: 'flex',
          alignItems: 'center',
        },
      },
    },
  },
});

const CareerVacancies5 = ({ classes, data }) => {
  return data && data.length ? (
    <section className={classes.root}>
      <div className='career container'>
        <div className='text-holder'>
          <h1 className='title'>Job Openings</h1>
        </div>
        <div className='row'>
          {data.map((item) => {
            return (
              <div className='col-sm-12 col-md-6 mb-4' key={item.id}>
                <div className='career-item'>
                  <div className='content'>
                    <h2 className='job-title mb-3'>{item.job_title}</h2>
                    <div className='specification'>
                      <ul>
                        <li>{item.employment_type} time</li>
                        <li>{item.no_of_vacancy} Post Openings</li>
                        <li>
                          {item.experience_required}+ years in related field
                        </li>
                      </ul>
                    </div>
                  </div>
                  <Button
                    variant='contained'
                    component={Link}
                    to={`career/${item.id}`}
                  >
                    Details
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  ) : null;
};

export default injectSheet(style)(CareerVacancies5);
