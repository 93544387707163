import React, { useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import parse from 'html-react-parser';
import './index.css';

const PopupModal = ({ resource_path, itemData }) => {
  const [modal, setModal] = useState(true);
  const toggle = () => setModal(!modal);

  const ExternalLink = ({ children }) => {
    return itemData.external_link ? (
      <a
        href={itemData.external_link}
        target='_blank'
        rel='noreferrer noopener'
      >
        {children}
      </a>
    ) : (
      children
    );
  };

  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <button
          type='button'
          className='close modal-close-btn'
          onClick={toggle}
          aria-label='Close'
        >
          <span aria-hidden='true'>×</span>
        </button>
        <ModalBody>
          <div className='popup-item' key={itemData.slug}>
            {itemData.image && (
              <ExternalLink>
                <img
                  className='img-fluid w-100'
                  src={resource_path + itemData.image}
                  alt={itemData.title}
                />
              </ExternalLink>
            )}
            {itemData.description && (
              <div
                className={`popup-description ${itemData.image ? 'mt-2' : ''} text-center`}
              >
                {parse(itemData.description)}
              </div>
            )}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default PopupModal;
